import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from '@shared/config/axios';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { Delete, Edit } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControl, InputLabel, MenuItem,
    Select, TextField,
    Toolbar,
} from '@mui/material';
import { PACK_STATUSES_ARR } from '@shared/api/constants';
import { TypographyPackStatus } from '@features/typography-colored-status-pack';
import { formatISOToCustom, isISO8601 } from '@shared/api/utils';

export const PacksPage = () => {
    const queryClient = useQueryClient();
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 15,
        page: 0,
    });

    const [editingRow, setEditingRow] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);

    const [optionRoastTypes, setOptionRoastTypes] = useState([]);
    const [optionCountries, setOptionCountries] = useState([]);
    const { data: countries } = useQuery({
        queryKey: ['countries-for-packs'],
        queryFn: () => {
            return axios.get(`/countries`).then((data) => data);
        }
    });
    const { data: roastTypes } = useQuery({
        queryKey: ['roastTypes-for-packs'],
        queryFn: () => {
            return axios.get(`/roast-types`).then((data) => data);
        }
    });

    useEffect(() => {
        if (roastTypes) {
            const newRoastTypes = roastTypes.data.map((item) => ( {
                label: item.name.en,
                value: item._id
            } ));
            setOptionRoastTypes(newRoastTypes);
        }
        if (countries) {
            const newCountries = countries.data.map((item) => ( {
                label: item.name,
                value: item._id
            } ));
            setOptionCountries(newCountries);
        }
    }, [roastTypes, countries]);

    const { data, isLoading } = useQuery({
        queryKey: ['packs'],
        queryFn: async () => {
            const response = await axios.get('/packs');
            return response.data;
        },
        keepPreviousData: true,
    });


    // Update bag mutation
    const updateMutation = useMutation({
        mutationFn: ({ packId, updatedPack }) => axios.patch(`/packs/${packId}`, updatedPack),
        onSuccess: () => {
            queryClient.invalidateQueries(['packs']);
            setDialogOpen(false);
        },
    });

    // Delete bag mutation
    const deleteMutation = useMutation({
        mutationFn: (id) => axios.delete(`/packs/${id}`),
        onSuccess: () => queryClient.invalidateQueries(['packs']),
    });

    const handleSave = () => {
        if (editingRow) {
            updateMutation.mutate({
                packId: editingRow._id,
                updatedPack: {
                    roastType: editingRow.roastType._id,
                    grind: editingRow.grind,
                    country: editingRow.country._id,
                    producerName: editingRow.producerName,
                    weight: editingRow.weight,
                    status: editingRow.status,
                }
            });
        }
    };


    const handleEdit = (row) => {
        setEditingRow(row);
        setDialogOpen(true);
    };

    const handleDelete = (id) => {
        deleteMutation.mutate(id);
    };

    const handleDialogClose = () => {
        setEditingRow(null);
        setDialogOpen(false);
    };

    const handleCountryChange = (event) => {
        const selectedValue = event.target.value;
        const selectedOption = optionCountries.find(option => option.value === selectedValue);
        setEditingRow({
            ...editingRow,
            country: {
                _id: selectedOption.value,
                name: selectedOption.label
            }
        });
    };


    const handleWeightChange = (event) => {
        const value = event.target.value;
        if (/^\d*\.?\d*$/.test(value)) {
            setEditingRow({ ...editingRow, weight: +value });
        }
    };

    const handleRoastTypeChange = (event) => {
        const selectedValue = event.target.value;
        const selectedOption = optionRoastTypes.find(option => option.value === selectedValue);
        setEditingRow({
            ...editingRow,
            roastType: {
                _id: selectedOption.value,
                name: selectedOption.label
            }
        });
    };

    const columns = [
        { field: '_id', headerName: 'ID', width: 70, valueGetter: (params) => params.slice(-5) },
        {
            field: 'orderDate',
            headerName: 'Order Date',
            width: 130,
            valueFormatter: (value) => {
                if (isISO8601(value)) {
                    return formatISOToCustom(value);
                }
                return value;
            },
        },
        {
            field: 'packNumber',
            type: 'number',
            headerName: 'Pack Number',
            width: 100,
        },
        {
            field: 'weight',
            type: 'number',
            headerName: 'Weight, kg',
            width: 80,
        },
        {
            field: 'grind',
            headerName: 'Grind',
            width: 150,
        },
        {
            field: 'roastType',
            headerName: 'Roast Type',
            width: 120,
            valueGetter: (params) => params?.name?.en
        }, {
            field: 'country',
            headerName: 'Country',
            width: 120,
            valueGetter: (params) => params?.name
        }, {
            field: 'producerName',
            headerName: 'Producer Name',
            width: 120
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 90,
            renderCell: (params) => (
                <TypographyPackStatus status={params.value} sx={{ height: '100%', alignContent: 'center' }}/>
            )
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: '',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ row }) => {
                return [
                    <GridActionsCellItem
                        icon={<Edit/>}
                        label="Edit"
                        onClick={() => handleEdit(row)}
                    />,
                    <GridActionsCellItem
                        icon={<Delete/>}
                        label="Delete"
                        onClick={() => handleDelete(row._id)}
                    />,
                ];
            }
        }
    ];

    return (
        <>
            <Toolbar/>
            <Container maxWidth="false" sx={{ mt: 4, mb: 4 }}>
                <Box
                    sx={{
                        height: '80vh',
                        width: '100%',
                    }}
                >
                    <DataGrid
                        rows={data || []}
                        columns={columns}
                        loading={isLoading}
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        pageSizeOptions={[5, 10, 15]}
                        getRowId={(row) => row._id}
                    />
                </Box>
            </Container>

            {/* Edit Dialog */}
            <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Edit Bag</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="select-country-label">Country</InputLabel>
                        <Select
                            variant="outlined"
                            labelId="select-country-label"
                            label="Country"
                            id="select-country"
                            value={editingRow?.country?._id || ''}
                            onChange={handleCountryChange}
                        >
                            {optionCountries.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Producer Name"
                        value={editingRow?.producerName || ''}
                        onChange={(e) => setEditingRow({ ...editingRow, producerName: e.target.value })}
                    />
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="select-roast-type-label">Roast Type</InputLabel>
                        <Select
                            variant="outlined"
                            labelId="select-roast-type-label"
                            label="Roast Type"
                            id="select-roast-type"
                            value={editingRow?.roastType?._id || ''}
                            onChange={handleRoastTypeChange}
                        >
                            {optionRoastTypes.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel id="select-grind-label">Grind</InputLabel>
                        <Select
                            variant="outlined"
                            labelId="select-grind-label"
                            label="Grind"
                            id="select-grind"
                            value={editingRow?.grind || ''}
                            onChange={(e) => setEditingRow({ ...editingRow, grind: e.target.value })}
                        >
                            <MenuItem value={'зерна'}>зерна</MenuItem>
                            <MenuItem value={'кемекс'}>кемекс</MenuItem>
                            <MenuItem value={'гейзерна кавоварка'}>гейзерна кавоварка</MenuItem>
                            <MenuItem value={'клевер'}>клевер</MenuItem>
                            <MenuItem value={'френч-прес'}>френч-прес</MenuItem>
                            <MenuItem value={'чашка'}>чашка</MenuItem>
                            <MenuItem value={'турка '}>турка </MenuItem>
                            <MenuItem value={'v60'}>v60</MenuItem>
                            <MenuItem value={'аеропресс'}>аеропресс</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Weight (kg)"
                        type="text"
                        value={editingRow?.weight || ''}
                        onChange={handleWeightChange}
                    />
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Status</InputLabel>
                        <Select
                            variant="outlined"
                            value={editingRow?.status || ''}
                            onChange={(e) => setEditingRow({ ...editingRow, status: e.target.value })}
                            label="Status"
                        >
                            {PACK_STATUSES_ARR.map((status) => (
                                <MenuItem key={status} value={status}>
                                    {status.charAt(0).toUpperCase() + status.slice(1).replace('_', ' ')}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleSave} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};