import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Box, Card, CardContent, Typography, Grid, CircularProgress, Toolbar, Container } from '@mui/material';
import axios from '@shared/config/axios';

export const RoastingPlanPage = () => {
    const { data, isLoading, isError } = useQuery({
        queryKey: ['roastPlan'],
        queryFn: () => axios.post('/orders/get-roast-plan').then((res) => res.data),
    });

    if (isLoading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress size={60}/>
            </Box>
        );
    }

    if (isError) {
        return (
            <Box sx={{ textAlign: 'center', marginTop: 4 }}>
                <Typography variant="h5" color="error">
                    Error loading roasting plan.
                </Typography>
            </Box>
        );
    }

    const totalWeight = data?.reduce((sum: number, row: {
        weightNeeded: number
    }) => sum + row.weightNeeded, 0).toFixed(2);

    return (
        <>
            <Toolbar/>
            <Container maxWidth="false" sx={{ mt: 4, mb: 4 }}>
                <Box
                    sx={{
                        height: '80vh',
                        width: '100%',
                        padding: 4
                    }}
                >
                    {/* Общий вес */}
                    <Typography variant="h5" sx={{ marginBottom: 4 }}>
                        Total Weight Needed: <span style={{ color: 'green' }}>{totalWeight} kg</span>
                    </Typography>

                    <Grid container spacing={4}>
                        {/* Карточки для каждого заказа */}
                        {data?.map((row: {
                            country: string;
                            producerName: string;
                            roastType: string;
                            weightNeeded: number
                        }, index: number) => (
                            <Grid item xs={12} md={6} key={index}>
                                <Card sx={{ boxShadow: 3, borderRadius: 2 }}>
                                    <CardContent>
                                        {/* Карточка с основными данными */}
                                        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
                                            {row.country} - {row.producerName}
                                        </Typography>
                                        <Typography variant="body1" sx={{ marginBottom: 2 }}>
                                            <strong>Roast Type:</strong> {row.roastType}
                                        </Typography>
                                        <Typography variant="body1">
                                            <strong>Weight Needed:</strong> {row.weightNeeded.toFixed(2)} kg
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Container>
        </>
    );
};
