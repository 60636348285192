import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import { ThemeProvider, CssBaseline } from '@mui/material';

import '@app/index.scss';
import { App } from '@app/App';
import { theme } from '@app/theme';
import { AuthProvider } from '@providers/context/AuthContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { TimerProvider } from '@providers/context/TimerContext';

const root = ReactDOM.createRoot(document.getElementById('root'));

const queryClient = new QueryClient();

root.render(
    <>
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <QueryClientProvider client={queryClient}>
                <BrowserRouter future={{ v7_relativeSplatPath: true, v7_startTransition: true }}>
                    <AuthProvider>
                        <TimerProvider>
                            <App/>
                        </TimerProvider>
                    </AuthProvider>
                </BrowserRouter>
            </QueryClientProvider>
        </ThemeProvider>
    </>
);