import React, { useState, useEffect, useRef } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
    Button,
    Container,
    FormControl,
    Grid,
    Paper,
    TextField,
    Autocomplete,
    Toolbar,
    Select,
    InputLabel,
    MenuItem
} from '@mui/material';

import axios from '@shared/config/axios';
import { INITIAL_VALUE_SELECT } from '@shared/api/constants';
import { Alert } from '@features/alert';
import { AxiosErrorAlertHandler } from '@shared/api/utils';

export const AddPackPage = () => {
    const alertRef = useRef();

    const [weight, setWeight] = useState('');
    const [selectedCountry, setSelectedCountry] = useState(INITIAL_VALUE_SELECT);
    const [count, setCount] = useState('');
    const [producerName, setProducerName] = useState('');
    const [selectedProcessing, setSelectedProcessing] = useState(INITIAL_VALUE_SELECT);
    const [selectedRoastType, setSelectedRoastType] = useState(INITIAL_VALUE_SELECT);
    const [grind, setGrind] = useState('');

    const [optionCountries, setOptionCountries] = useState([]);
    const [optionProcesses, setOptionProcesses] = useState([]);
    const [optionRoastTypes, setOptionRoastTypes] = useState([]);

    const { data: countries } = useQuery({
        queryKey: ['countries-for-pack'],
        queryFn: () => {
            return axios.get(`/countries`).then((data) => data);
        }
    });

    const { data: processes } = useQuery({
        queryKey: ['processes-for-pack'],
        queryFn: () => {
            return axios.get(`/processes`).then((data) => data);
        }
    });

    const { data: roastTypes } = useQuery({
        queryKey: ['roastTypes-for-pack'],
        queryFn: () => {
            return axios.get(`/roast-types`).then((data) => data);
        }
    });

    useEffect(() => {
        if (countries) {
            const newCountries = countries.data.map((item) => ( {
                label: item.name,
                value: item._id
            } ));
            setOptionCountries(newCountries);
        }
    }, [countries]);

    useEffect(() => {
        if (processes) {
            const newProcesses = processes.data.map((item) => ( {
                label: item.name.en,
                value: item._id
            } ));
            setOptionProcesses(newProcesses);
        }
    }, [processes]);

    useEffect(() => {
        if (roastTypes) {
            const newRoastTypes = roastTypes.data.map((item) => ( {
                label: item.name.en,
                value: item._id
            } ));
            setOptionRoastTypes(newRoastTypes);
        }
    }, [roastTypes]);

    const clearStates = () => {
        setWeight('');
        setGrind('');
        setCount('');
        setSelectedCountry(null);
        setSelectedProcessing(null);
        setSelectedRoastType(null);
        setProducerName('');
    };

    const handleRoastTypeChange = (event) => {
        const selectedValue = event.target.value;
        const selectedOption = optionRoastTypes.find(option => option.value === selectedValue);
        setSelectedRoastType(selectedOption);
    };

    const handleProducerNameChange = (event) => {
        const value = event.target.value;
        setProducerName(value);
    };

    const handleCountChange = (event) => {
        const value = event.target.value;

        if (value === '' || /^\d+$/.test(value)) {
            const numericValue = value === '' ? '' : parseInt(value, 10);
            if (value === '' || ( numericValue > 0 && numericValue <= 50 )) {
                setCount(value);
            }
        }
    };

    const handleCountryChange = (event, newValue) => {
        setSelectedCountry(newValue);
    };

    const handleProcessingChange = (event) => {
        const selectedValue = event.target.value;
        const selectedOption = optionProcesses.find(option => option.value === selectedValue);
        setSelectedProcessing(selectedOption);
    };

    const handleWeightChange = (event) => {
        const value = event.target.value;
        if (/^\d*\.?\d*$/.test(value)) {
            setWeight(value);
        }
    };

    const handleGrindChange = (event) => {
        const value = event.target.value;
        setGrind(value);
    };

    const handleSubmit = async () => {
        if (!weight || !grind || !count || !selectedCountry || !selectedProcessing || !selectedRoastType || !producerName) {
            alertRef.current.handleOpen('warning', 'Please fill out all fields!');
            return;
        }

        const newPack = {
            weight: weight,
            grind: grind,
            country: selectedCountry ? selectedCountry.value : '',
            processing: selectedProcessing ? selectedProcessing.value : '',
            roastType: selectedRoastType ? selectedRoastType.value : '',
            producerName: producerName,
            orderDate: ( new Date() ).toISOString()
        };

        try {
            await axios.post('/packs', newPack, {
                params: {
                    count
                },
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            alertRef.current.handleOpen('success', 'Pack created successfully.');
            clearStates();
        } catch (error) {
            AxiosErrorAlertHandler(error, alertRef);
        }
    };

    return (
        <>
            <Toolbar/>
            <Container
                maxWidth="sm"
                sx={{ marginTop: 4, display: 'flex', justifyContent: 'center' }}
            >
                <Paper sx={{ p: 2, width: '100%' }}>
                    <Grid container spacing={2}>
                        {/* Weight */}
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <InputLabel id="select-weight-label">Weight</InputLabel>
                                <Select
                                    variant="outlined"
                                    labelId="select-weight-label"
                                    label="Weight"
                                    id="select-weight"
                                    value={weight}
                                    onChange={handleWeightChange}
                                >
                                    <MenuItem value={0.250}>250 g</MenuItem>
                                    <MenuItem value={0.500}>500 g</MenuItem>
                                    <MenuItem value={1}>1000 g</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        {/* Grind */}
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id="select-grind-label">Grind</InputLabel>
                                <Select
                                    variant="outlined"
                                    labelId="select-grind-label"
                                    label="Grind"
                                    id="select-grind"
                                    value={grind}
                                    onChange={handleGrindChange}
                                >
                                    <MenuItem value={'зерна'}>зерна</MenuItem>
                                    <MenuItem value={'кемекс'}>кемекс</MenuItem>
                                    <MenuItem value={'гейзерна кавоварка'}>гейзерна кавоварка</MenuItem>
                                    <MenuItem value={'клевер'}>клевер</MenuItem>
                                    <MenuItem value={'френч-прес'}>френч-прес</MenuItem>
                                    <MenuItem value={'чашка'}>чашка</MenuItem>
                                    <MenuItem value={'турка '}>турка </MenuItem>
                                    <MenuItem value={'v60'}>v60</MenuItem>
                                    <MenuItem value={'аеропресс'}>аеропресс</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        {/* Count */}
                        <Grid item xs={2}>
                            <FormControl>
                                <TextField
                                    label="Count"
                                    variant="outlined"
                                    value={count}
                                    onChange={handleCountChange}
                                    fullWidth
                                />
                            </FormControl>
                        </Grid>
                        {/* Country */}
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    options={optionCountries}
                                    autoHighlight
                                    getOptionLabel={(option) => option.label}
                                    value={selectedCountry}
                                    isOptionEqualToValue={(option, value) => option._id === value._id}
                                    onChange={handleCountryChange}
                                    renderInput={(params) => <TextField {...params} label="Country"/>}
                                    sx={{ width: '100%' }}
                                />
                            </FormControl>
                        </Grid>
                        {/* Producer Name */}
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <TextField
                                    label="Producer Name"
                                    variant="outlined"
                                    value={producerName}
                                    onChange={handleProducerNameChange}
                                    fullWidth
                                />
                            </FormControl>
                        </Grid>
                        {/* Processing */}
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id="select-processing-label">Processing</InputLabel>
                                <Select
                                    variant="outlined"
                                    labelId="select-processing-label"
                                    label="Processing"
                                    id="select-processing"
                                    value={selectedProcessing?.value || ''}
                                    onChange={handleProcessingChange}
                                >
                                    {optionProcesses.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {/* Roast Type */}
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id="select-roast-type-label">Roast Type</InputLabel>
                                <Select
                                    variant="outlined"
                                    labelId="select-roast-type-label"
                                    label="Roast Type"
                                    id="select-roast-type"
                                    value={selectedRoastType?.value || ''}
                                    onChange={handleRoastTypeChange}
                                >
                                    {optionRoastTypes.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {/* Create Button*/}
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                                fullWidth
                            >
                                Create Pack
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
            <Alert ref={alertRef}/>
        </>
    );
};
