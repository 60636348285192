import { createTheme } from '@mui/material/styles';


export const theme = createTheme({
    palette: {
        primary: {
            main: '#000000FF',
        }
    },
    typography: {
        // typography: {
        fontFamily: 'Bahnschrift Condensed',
        fontSize: 16,
        // },
        button: {
            textTransform: 'none',
            fontWeight: 400,
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                '::-webkit-scrollbar': {
                    width: 0,
                    background: 'transparent'
                },
            },
        },

    }
});