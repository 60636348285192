import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import axios from '@shared/config/axios';

export const useBags = () => {
    const navigate = useNavigate();
    const alertRef = useRef();

    const [rows, setRows] = useState([]);
    const [open, setOpen] = useState(false);
    const [qrCodeValue, setQrCodeValue] = useState('');

    const { data, isLoading } = useQuery({
        queryKey: ['bag'],
        queryFn: () => {
            return axios.get(`/bags`)
                .then(({ data }) => {
                    setRows(data);
                    return data;
                });
        },
        refetchOnWindowFocus: true,
        retry: false,
    });

    useEffect(() => {
        if (data) {
            setRows(data);
        }
    }, [data]);

    const handleOpenQr = (value) => {
        setQrCodeValue(value);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setQrCodeValue('');
    };

    const handleOpen = (id) => {
        navigate(`/bags/${id}`);
    };

    return {
        alertRef,
        rows,
        open,
        qrCodeValue,
        handleOpenQr,
        handleClose,
        handleOpen,
        isLoading
    };
};
