import { Box, Button, Container, Grid, Paper, Toolbar, Typography } from '@mui/material';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { usePack } from '../api/usePack';
import { TypographyPackStatus } from '@features/typography-colored-status-pack';
import { BATCH_STATUSES } from '@shared/api/constants';
import { Alert } from '@features/alert';

export const PackPage = () => {
    const { id: packId } = useParams();

    const {
        alertRef, pack,
        handleStartPacking, mutationStart,
        handleFinishPacking, mutationFinish
    } = usePack(packId);

    return (
        <>
            <Toolbar/>
            <Box
                sx={{
                    position: 'relative',
                    top: '16px',
                    left: '16px',
                }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to={`../packs`}
                    sx={{ position: 'absolute', left: '16px' }}
                >
                    Back
                </Button>
            </Box>
            <Container maxWidth="sm" sx={{ mt: 4, mb: 4 }}>
                <Paper sx={{ p: 2, width: '100%' }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Box display="flex" justifyContent="space-between"
                                 sx={{ borderBottom: '1px solid #ccc', pb: 2 }}>
                                <Typography variant="h5">Pack information</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="body1">{pack?.country?.name} {pack?.producerName}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <TypographyPackStatus status={pack?.status} variant="body1"/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1"><strong>Roast Type:</strong> {pack?.roastType?.name?.en}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1"><strong>Grind:</strong> {pack?.grind}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1"><strong>Weight:</strong> {pack?.weight} kg</Typography>
                        </Grid>
                        {pack?.status === BATCH_STATUSES.IN_PROGRESS && (
                            <Grid item xs={12}>
                                <Typography variant="body1">
                                    <strong>Bucket:</strong> {pack?.batch?.bucketId}
                                </Typography>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            {pack?.status === BATCH_STATUSES.INITIALIZED && (
                                <Button
                                    variant="contained"
                                    color="success"
                                    onClick={handleStartPacking}
                                    disabled={mutationStart.isLoading}
                                >
                                    Start Packing
                                </Button>
                            )}
                            {pack?.status === BATCH_STATUSES.IN_PROGRESS && (
                                <Button
                                    variant="contained"
                                    color="warning"
                                    onClick={handleFinishPacking}
                                    disabled={mutationFinish.isLoading}
                                >
                                    Confirm Packing
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
            <Alert ref={alertRef}/>
        </>
    );
};