// TimerContext.js
import React, { createContext, useContext, useState, useRef, useLayoutEffect } from 'react';

const TimerContext = createContext();

export const useTimer = () => useContext(TimerContext);

export const TimerProvider = ({ children }) => {
    const [startAt, setStartAt] = useState();
    const [elId, setElId] = useState('');
    const updateInterval = 1000; // Интервал обновления в миллисекундах

    const useNow = (enabled, cb) => {
        const cbRef = useRef(cb);
        cbRef.current = cb;
        const [now, setNow] = useState(Date.now());

        useLayoutEffect(() => {
            if (!enabled) {
                return;
            }

            setNow(Date.now());
            cbRef.current?.(Date.now());

            const interval = setInterval(() => {
                setNow(Date.now());
                cbRef.current?.(Date.now());
            }, updateInterval);

            return () => {
                clearInterval(interval);
            };
        }, [enabled]);

        return now;
    };

    const now = useNow(!!startAt, null);
    const elapsedTime = now - ( startAt ?? now ); // Разница во времени с момента начала

    const startTimer = () => {
        setStartAt(Date.now());
    };

    const stopTimer = () => {
        setStartAt(null);
    };

    return (
        <TimerContext.Provider value={{ elId, setElId, elapsedTime, startTimer, stopTimer }}>
            {children}
        </TimerContext.Provider>
    );
};
