import { Box, useMediaQuery } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { useAuth } from '@providers/context/AuthContext';
import { UserHeader } from '@widgets/user-header';
import { AdminHeader } from '@widgets/admin-header';

export const AdminLayout = () => {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const { isAuth } = useAuth();

    return (
        <Box sx={{ display: 'flex' }}>
            {!isMobile && isAuth && <AdminHeader/>}
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light' ? '#fafafa' : theme.palette.grey[900],
                }}
            >
                <Outlet/> {/* Контент для основной части сайта */}
            </Box>
        </Box>
    );
};

export const UserLayout = () => {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const { isAuth } = useAuth();

    return (
        <Box sx={{ display: 'flex' }}>
            {!isMobile && isAuth && <UserHeader/>}
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light' ? '#fafafa' : theme.palette.grey[900],
                }}
            >
                <Outlet/> {/* Контент для основной части сайта */}
            </Box>
        </Box>
    );
};