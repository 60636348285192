import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from '@shared/config/axios';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { Delete, Edit } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import {
    Box,
    Button, Chip,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControl, Grid, InputLabel, MenuItem,
    Paper, Select, TextField,
    Toolbar, Typography
} from '@mui/material';
import { TypographyBatchStatus } from '@features/typography-colored-status-batch';
import { TypographyBagStatus } from '@features/typography-colored-status-bag';
import { BATCH_STATUSES_ARR } from '@shared/api/constants';
import { formatISOToCustom, isISO8601 } from '@shared/api/utils';

export const BatchesPage = () => {
    const queryClient = useQueryClient();
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 15,
        page: 0,
    });

    const [editingRow, setEditingRow] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);

    const [open, setOpen] = useState(false);
    const [selectedBatch, setSelectedBatch] = useState(null);
    const [optionRoastTypes, setOptionRoastTypes] = useState([]);

    const { data: roastTypes } = useQuery({
        queryKey: ['roastTypes-for-bags'],
        queryFn: () => {
            return axios.get(`/roast-types`).then((data) => data);
        }
    });

    useEffect(() => {
        if (roastTypes) {
            const newRoastTypes = roastTypes.data.map((item) => ( {
                label: item.name.en,
                value: item._id
            } ));
            setOptionRoastTypes(newRoastTypes);
        }
    }, [roastTypes]);

    const handleOpenDialog = async (params) => {
        const response = await axios.get(`/bags/${params.row.bag._id}`);
        setSelectedBatch(response.data);
        setOpen(true);
    };

    const { data, isLoading } = useQuery({
        queryKey: ['batches'],
        queryFn: async () => {
            const response = await axios.get('/batches');
            return response.data;
        },
        keepPreviousData: true,
    });


    // Update bag mutation
    const updateMutation = useMutation({
        mutationFn: ({ batchId, updatedBatch }) => axios.patch(`/batches/${batchId}`, updatedBatch),
        onSuccess: () => {
            queryClient.invalidateQueries(['batches']);
            setDialogOpen(false);
        },
    });

    // Delete bag mutation
    const deleteMutation = useMutation({
        mutationFn: (id) => axios.delete(`/batches/${id}`),
        onSuccess: () => queryClient.invalidateQueries(['batches']),
    });

    const handleSave = () => {
        if (editingRow) {
            updateMutation.mutate({
                batchId: editingRow._id,
                updatedBatch: {
                    arrivalDate: editingRow.arrivalDate,
                    roastType: editingRow.roastType._id,
                    weightBefore: editingRow.weightBefore,
                    ...( editingRow.weightAfter && { weightAfter: editingRow.weightAfter } ),
                    status: editingRow.status,
                }
            });
        }
    };


    const handleEdit = (row) => {
        setEditingRow(row);
        setDialogOpen(true);
    };

    const handleDelete = (id) => {
        deleteMutation.mutate(id);
    };

    const handleDialogClose = () => {
        setEditingRow(null);
        setDialogOpen(false);
    };

    const handleWeightBeforeChange = (event) => {
        const value = event.target.value;
        if (/^\d*\.?\d*$/.test(value)) {
            setEditingRow({ ...editingRow, weightBefore: +value });
        }
    };


    const handleWeightAfterChange = (event) => {
        const value = event.target.value;
        if (/^\d*\.?\d*$/.test(value)) {
            setEditingRow({ ...editingRow, weightAfter: +value });
        }
    };

    const handleRoastTypeChange = (event) => {
        const selectedValue = event.target.value;
        const selectedOption = optionRoastTypes.find(option => option.value === selectedValue);
        setEditingRow({
            ...editingRow,
            roastType: {
                _id: selectedOption.value,
                name: selectedOption.label
            }
        });
    };

    const columns = [
        { field: '_id', headerName: 'ID', width: 70, valueGetter: (params) => params.slice(-5) },
        {
            field: 'arrivalDate',
            headerName: 'Arrival Date',
            width: 130,
            valueFormatter: (value) => {
                if (isISO8601(value)) {
                    return formatISOToCustom(value);
                }
                return value;
            }
        },
        {
            field: 'roastType',
            headerName: 'Roast Type',
            width: 100,
            valueGetter: (params) => params?.name?.en
        },
        {
            field: 'weightBefore',
            type: 'number',
            headerName: 'Weight Before, kg',
            width: 130,
        },
        {
            field: 'weightAfter',
            type: 'number',
            headerName: 'Weight After, kg',
            width: 120,
        },
        {
            field: 'adjustedWeight',
            type: 'number',
            headerName: 'Weight',
            width: 60,
            valueGetter: (params) => {
                if (params === 0) {
                    return '';
                }
                return params;
            }
        },
        {
            field: 'bucketId',
            headerName: 'BID',
            width: 60,
        },
        {
            field: 'operator',
            headerName: 'Operator',
            width: 120,
            valueGetter: (params) => params?.login
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 90,
            renderCell: (params) => (
                <TypographyBatchStatus status={params.value} sx={{ height: '100%', alignContent: 'center' }}/>
            )
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: '',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ row }) => {
                return [
                    <GridActionsCellItem
                        icon={<Edit/>}
                        label="Edit"
                        onClick={() => handleEdit(row)}
                    />,
                    <GridActionsCellItem
                        icon={<Delete/>}
                        label="Delete"
                        onClick={() => handleDelete(row._id)}
                    />,
                ];
            }
        }
    ];

    return (
        <>
            <Toolbar/>
            <Container maxWidth="false" sx={{ mt: 4, mb: 4 }}>
                <Box
                    sx={{
                        height: '80vh',
                        width: '100%',
                    }}
                >
                    <DataGrid
                        rows={data || []}
                        columns={columns}
                        loading={isLoading}
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        pageSizeOptions={[5, 10, 15]}
                        getRowId={(row) => row._id}
                        onRowClick={handleOpenDialog}
                    />
                </Box>
            </Container>

            {/* BAG INFORMATION*/}
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>
                    <Typography variant="h5" gutterBottom sx={{ borderBottom: '2px solid #ccc', pb: 2 }}>
                        Bag Details
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    {selectedBatch && (
                        <Paper
                            sx={{
                                p: 3,
                                display: 'flex',
                                flexDirection: 'column',
                                height: 'auto',
                                backgroundColor: '#f9f9f9',
                                boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                                borderRadius: 2
                            }}
                        >
                            <Grid container spacing={2} sx={{ mt: 2 }}>
                                {/* ID */}
                                <Grid item xs={12} sm={6}>
                                    <Box display="flex" justifyContent="space-between"
                                         sx={{ borderBottom: '1px solid #ccc', pb: 2 }}>
                                        <Typography variant="body1"><strong>ID:</strong></Typography>
                                        <Typography variant="body1">{selectedBatch?._id}</Typography>
                                    </Box>
                                </Grid>
                                {/* Date */}
                                <Grid item xs={12} sm={6}>
                                    <Box display="flex" justifyContent="space-between"
                                         sx={{ borderBottom: '1px solid #ccc', pb: 2 }}>
                                        <Typography variant="body1"><strong>Arrival Date:</strong></Typography>
                                        <Typography variant="body1">{selectedBatch?.arrivalDate}</Typography>
                                    </Box>
                                </Grid>
                                {/* Lot Number */}
                                <Grid item xs={12} sm={6}>
                                    <Box display="flex" justifyContent="space-between"
                                         sx={{ borderBottom: '1px solid #ccc', pb: 2 }}>
                                        <Typography variant="body1"><strong>Lot Number:</strong></Typography>
                                        <Typography variant="body1">{selectedBatch?.lotNumber}</Typography>
                                    </Box>
                                </Grid>
                                {/* Country */}
                                <Grid item xs={12} sm={6}>
                                    <Box display="flex" justifyContent="space-between"
                                         sx={{ borderBottom: '1px solid #ccc', pb: 2 }}>
                                        <Typography variant="body1"><strong>Country:</strong></Typography>
                                        <Typography variant="body1">{selectedBatch?.country?.name}</Typography>
                                    </Box>
                                </Grid>
                                {/* Region */}
                                <Grid item xs={12}>
                                    <Box display="flex" justifyContent="space-between"
                                         sx={{ borderBottom: '1px solid #ccc', pb: 2 }}>
                                        <Typography variant="body1"><strong>Region:</strong></Typography>
                                        <Typography variant="body1">{selectedBatch?.region}</Typography>
                                    </Box>
                                </Grid>
                                {/* Producer Name */}
                                <Grid item xs={12} sm={6}>
                                    <Box display="flex" justifyContent="space-between"
                                         sx={{ borderBottom: '1px solid #ccc', pb: 2 }}>
                                        <Typography variant="body1"><strong>Producer Name:</strong></Typography>
                                        <Typography variant="body1">{selectedBatch?.producerName}</Typography>
                                    </Box>
                                </Grid>
                                {/* Harvest */}
                                <Grid item xs={12} sm={6}>
                                    <Box display="flex" justifyContent="space-between"
                                         sx={{ borderBottom: '1px solid #ccc', pb: 2 }}>
                                        <Typography variant="body1"><strong>Harvest:</strong></Typography>
                                        <Typography variant="body1">{selectedBatch?.harvest}</Typography>
                                    </Box>
                                </Grid>
                                {/* Processing */}
                                <Grid item xs={12}>
                                    <Box display="flex" justifyContent="space-between"
                                         sx={{ borderBottom: '1px solid #ccc', pb: 2 }}>
                                        <Typography variant="body1"><strong>Processing:</strong></Typography>
                                        <Typography variant="body1">{selectedBatch?.processing?.name?.en}</Typography>
                                    </Box>
                                </Grid>
                                {/* Type */}
                                <Grid item xs={12} sm={4}>
                                    <Box display="flex" justifyContent="space-between"
                                         sx={{ borderBottom: '1px solid #ccc', pb: 2 }}>
                                        <Typography variant="body1"><strong>Type:</strong></Typography>
                                        <Typography variant="body1">{selectedBatch?.type?.name}</Typography>
                                    </Box>
                                </Grid>
                                {/* Q-Grade */}
                                <Grid item xs={12} sm={4}>
                                    <Box display="flex" justifyContent="space-between"
                                         sx={{ borderBottom: '1px solid #ccc', pb: 2 }}>
                                        <Typography variant="body1"><strong>Q-Grade:</strong></Typography>
                                        <Typography variant="body1">{selectedBatch?.qGrade}</Typography>
                                    </Box>
                                </Grid>
                                {/* Altitude */}
                                <Grid item xs={12} sm={4}>
                                    <Box display="flex" justifyContent="space-between"
                                         sx={{ borderBottom: '1px solid #ccc', pb: 2 }}>
                                        <Typography variant="body1"><strong>Altitude:</strong></Typography>
                                        <Typography variant="body1">{selectedBatch?.altitude} m</Typography>
                                    </Box>
                                </Grid>
                                {/* Variety */}
                                <Grid item xs={12}>
                                    <Box display="flex" justifyContent="space-between"
                                         sx={{ borderBottom: '1px solid #ccc', pb: 2 }}>
                                        <Typography variant="body1"
                                                    sx={{ mt: 0.5 }}><strong>Variety:</strong></Typography>
                                        <Box display="flex" flexWrap="wrap" gap={1} maxWidth="70%">
                                            {selectedBatch?.variety ? (
                                                selectedBatch.variety.map((v) => (
                                                    <Chip key={v._id} label={v.name}/>
                                                ))
                                            ) : (
                                                ''
                                            )}
                                        </Box>
                                    </Box>
                                </Grid>
                                {/* Descriptors */}
                                <Grid item xs={12}>
                                    <Box display="flex" justifyContent="space-between"
                                         sx={{ borderBottom: '1px solid #ccc', pb: 2 }}>
                                        <Typography variant="body1"
                                                    sx={{ mr: 1 }}><strong>Descriptors:</strong></Typography>
                                        <Typography variant="body1"
                                                    sx={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                                            {selectedBatch?.descriptors}
                                        </Typography>
                                    </Box>
                                </Grid>
                                {/* Weight */}
                                <Grid item xs={12} sm={6}>
                                    {( selectedBatch?.weight || selectedBatch?.weight === 0 )
                                        ? (
                                            <Box display="flex" justifyContent="space-between"
                                                 sx={{ borderBottom: '1px solid #ccc', pb: 2 }}>
                                                <Typography variant="body1"><strong>Weight:</strong></Typography>
                                                <Typography
                                                    variant="body1">{selectedBatch?.adjustedWeight.toFixed(1)} kg</Typography>
                                            </Box>
                                        ) : (
                                            <Box display="flex" justifyContent="space-between"
                                                 sx={{
                                                     borderRadius: '10px',
                                                     pb: 2,
                                                     px: 1,
                                                     backgroundColor: '#ffcccc'
                                                 }}>
                                                <Typography variant="body1"><strong>Weight:</strong></Typography>
                                                <Typography
                                                    variant="body1">{selectedBatch?.adjustedWeight} kg</Typography>
                                            </Box>
                                        )}
                                </Grid>
                                {/* Status */}
                                <Grid item xs={12} sm={6}>
                                    <Box display="flex" justifyContent="space-between"
                                         sx={{ borderBottom: '1px solid #ccc', pb: 2 }}>
                                        <Typography variant="body1"><strong>Status:</strong></Typography>
                                        <TypographyBagStatus status={selectedBatch?.status} variant="body1"/>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Paper>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="secondary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Edit Dialog */}
            <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Edit Bag</DialogTitle>
                <DialogContent>
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Arrival Date"
                        value={editingRow?.arrivalDate || ''}
                        onChange={(e) => setEditingRow({ ...editingRow, arrivalDate: e.target.value })}
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Bucket ID"
                        value={editingRow?.bucketId || ''}
                        onChange={(e) => setEditingRow({ ...editingRow, bucketId: e.target.value })}
                    />
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="select-roast-type-label">Roast Type</InputLabel>
                        <Select
                            variant="outlined"
                            labelId="select-roast-type-label"
                            label="Roast Type"
                            id="select-roast-type"
                            value={editingRow?.roastType?._id || ''}
                            onChange={handleRoastTypeChange}
                        >
                            {optionRoastTypes.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Weight Before (kg)"
                        type="text"
                        value={editingRow?.weightBefore || ''}
                        onChange={handleWeightBeforeChange}
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Weight After (kg)"
                        type="text"
                        value={editingRow?.weightAfter || ''}
                        onChange={handleWeightAfterChange}
                    />
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Status</InputLabel>
                        <Select
                            variant="outlined"
                            value={editingRow?.status || ''}
                            onChange={(e) => setEditingRow({ ...editingRow, status: e.target.value })}
                            label="Status"
                        >
                            {BATCH_STATUSES_ARR.map((status) => (
                                <MenuItem key={status} value={status}>
                                    {status.charAt(0).toUpperCase() + status.slice(1).replace('_', ' ')}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleSave} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};