import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Box, Button, Container, Grid, Paper, Toolbar, Typography } from '@mui/material';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from '@shared/config/axios';
import { TypographyBatchStatus } from '@features/typography-colored-status-batch';
import { BATCH_STATUSES } from '@shared/api/constants';
import { useTimer } from '@providers/context/TimerContext';
import { useAuth } from '@providers/context/AuthContext';
import { Alert } from '@features/alert';
import { formatISOToCustom, isISO8601 } from '@shared/api/utils';

export const BatchViewPage = () => {
    const { id: batchId } = useParams();
    const queryClient = useQueryClient();
    const { userInfo } = useAuth();
    const alertRef = useRef();

    const [batchData, setBatchData] = useState(null);
    const { elId, setElId, elapsedTime, startTimer, stopTimer } = useTimer();

    const { data, refetch } = useQuery({
        queryKey: ['batch', batchId],
        queryFn: () => axios.get(`/users/${userInfo?._id}/batches/${batchId}`).then(({ data }) => data),
        refetchOnWindowFocus: true,
        retry: false,
    });

    useEffect(() => {
        if (data) {
            setBatchData(data);
        }
    }, [data]);

    const mutation = useMutation({
        mutationFn: (updateObj) => {
            return axios.patch(`/batches/${batchId}/`, { ...updateObj });
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['batch', batchId]); // Это обновит данные в кэше
            refetch();
        },
    });

    const handleStartRoasting = () => {
        startTimer();
        setElId(batchId);
        mutation.mutate({ status: BATCH_STATUSES.IN_PROGRESS });
    };

    const handleFinishRoasting = () => {
        stopTimer();
        setElId('');
        mutation.mutate({ status: BATCH_STATUSES.COMPLETED, roastingTime: elapsedTime });
        alertRef.current.handleOpen('success', 'Batch roasting successfully.');
    };

    useEffect(() => {
        if (data) {
            setBatchData(data);
        }

        if (batchId) {
            localStorage.setItem('lastSelectedBatchId', batchId);
        }
    }, [data, batchId]);

    const formatTime = (timeInSeconds) => {
        const minutes = Math.floor(( timeInSeconds % 3600 ) / 60);
        const seconds = Math.floor(timeInSeconds % 60);
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    return (
        <>
            <Toolbar/>
            <Box
                sx={{
                    position: 'relative',
                    top: '16px',
                    left: '16px',
                }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to={`../batches`}
                    sx={{ position: 'absolute', left: '16px' }}
                >
                    Back
                </Button>
            </Box>
            <Container
                maxWidth={false}
                sx={{
                    marginTop: 4,
                    marginBottom: 4,
                    height: '88vh',
                    display: 'flex',
                    padding: '0',
                    justifyContent: 'center',
                }}
            >
                <Paper
                    sx={{
                        height: '300px',
                        width: '300px',
                        padding: '8px',
                        overflow: 'hidden',
                        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                    }}
                >
                    <div>
                        <Typography variant="h5">Batch Information</Typography>
                        <Typography variant="body1">Bucket ID: {batchData?.bucketId}</Typography>
                        <Typography variant="body1">
                                        {batchData?.arrivalDate
                                            ? isISO8601(batchData.arrivalDate)
                                                ? formatISOToCustom(batchData.arrivalDate)
                                                : batchData.arrivalDate
                                            : ''
                                        }
                                    </Typography>
                        <TypographyBatchStatus status={batchData?.status} variant="body1"/>
                        <Grid item xs={12} container direction="row">
                            <Grid item xs={6}>
                                <Typography variant="body1">{batchData?.weightBefore} kg</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body1">{batchData?.roastType?.name?.en}</Typography>
                            </Grid>
                        </Grid>
                        <Typography variant="body1">{batchData?.bag?.country?.name}</Typography>
                    </div>

                    <Typography variant="h6" sx={{ textAlign: 'center' }}>
                        {( elId !== batchId || elId === '' ) ? '00:00' : formatTime(elapsedTime / 1000)}
                    </Typography>

                    {batchData?.status === BATCH_STATUSES.INITIALIZED && (
                        <Button
                            variant="contained"
                            color="success"
                            onClick={handleStartRoasting}
                            disabled={mutation.isLoading || ( elId !== batchId && elId !== '' )}
                        >
                            Start Roasting
                        </Button>
                    )}
                    {batchData?.status === BATCH_STATUSES.IN_PROGRESS && (
                        <Button
                            variant="contained"
                            color="warning"
                            onClick={handleFinishRoasting}
                            disabled={mutation.isLoading}
                        >
                            End Roasting
                        </Button>
                    )}
                </Paper>
            </Container>
            <Alert ref={alertRef}/>
        </>
    );
};
