import {
    Toolbar,
    Container,
    Box,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField, FormControl, InputLabel, Select, MenuItem, OutlinedInput, Chip,
} from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { Edit, Delete } from '@mui/icons-material';

import axios from '@shared/config/axios';
import { TypographyBagStatus } from '@features/typography-colored-status-bag';
import { useTheme } from '@mui/material/styles';
import { BAG_STATUSES_ARR } from '@shared/api/constants';
import { formatISOToCustom, isISO8601 } from '@shared/api/utils';


export const BagsPage = () => {
    const theme = useTheme();

    const queryClient = useQueryClient();
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 15,
        page: 0,
    });
    const [editingRow, setEditingRow] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);

    // Options for select
    const [optionCountries, setOptionCountries] = useState([]);
    const [optionVarieties, setOptionVarieties] = useState([]);
    const [optionTypes, setOptionTypes] = useState([]);
    const [optionProcesses, setOptionProcesses] = useState([]);

    const { data: varieties } = useQuery({
        queryKey: ['varieties-for-bags'],
        queryFn: () => {
            return axios.get(`/varieties`).then((data) => data);
        }
    });

    const { data: countries } = useQuery({
        queryKey: ['countries-for-bags'],
        queryFn: () => {
            return axios.get(`/countries`).then((data) => data);
        }
    });

    const { data: types } = useQuery({
        queryKey: ['types-for-bags'],
        queryFn: () => {
            return axios.get(`/types`).then((data) => data);
        }
    });

    const { data: processes } = useQuery({
        queryKey: ['processes-for-bags'],
        queryFn: () => {
            return axios.get(`/processes`).then((data) => data);
        }
    });

    useEffect(() => {
        if (varieties) {
            const newVarieties = varieties.data.map((item) => ( {
                label: item.name,
                value: item._id
            } ));
            setOptionVarieties(newVarieties);
        }
        if (countries) {
            const newCountries = countries.data.map((item) => ( {
                label: item.name,
                value: item._id
            } ));
            setOptionCountries(newCountries);
        }
        if (types) {
            const newTypes = types.data.map((item) => ( {
                label: item.name,
                value: item._id
            } ));
            setOptionTypes(newTypes);
        }
        if (processes) {
            const newProcesses = processes.data.map((item) => ( {
                label: item.name?.en,
                value: item._id
            } ));
            setOptionProcesses(newProcesses);
        }
    }, [varieties, countries, types, processes]);

    // Fetch bags with backend pagination
    const { data, isLoading } = useQuery({
        queryKey: ['bags', paginationModel],
        queryFn: async () => {
            const response = await axios.get('/bags');
            return response.data;
        },
        keepPreviousData: true,
    });

    // Update bag mutation
    const updateMutation = useMutation({
        mutationFn: ({ bagId, updatedBag }) => axios.patch(`/bags/${bagId}`, updatedBag),
        onSuccess: () => {
            queryClient.invalidateQueries(['bags']);
            setDialogOpen(false);
        },
    });

    // Delete bag mutation
    const deleteMutation = useMutation({
        mutationFn: (id) => axios.delete(`/bags/${id}`),
        onSuccess: () => queryClient.invalidateQueries(['bags']),
    });

    const handleEdit = (row) => {
        setEditingRow(row);
        setDialogOpen(true);
    };

    const handleDelete = (id) => {
        deleteMutation.mutate(id);
    };

    const handleSave = () => {
        if (editingRow) {
            updateMutation.mutate({
                bagId: editingRow._id,
                updatedBag: {
                    altitude: editingRow.altitude,
                    arrivalDate: editingRow.arrivalDate,
                    country: editingRow.country._id,
                    descriptors: editingRow.descriptors,
                    harvest: editingRow.harvest,
                    lotNumber: editingRow.lotNumber,
                    processing: editingRow.processing._id,
                    producerName: editingRow.producerName,
                    qGrade: editingRow.qGrade,
                    region: editingRow.region,
                    status: editingRow.status,
                    type: editingRow.type._id,
                    variety: editingRow.variety.map(item => item._id),
                    ...( editingRow.weight && { weight: editingRow.weight } )
                }
            });
        }
    };

    const handleDialogClose = () => {
        setEditingRow(null);
        setDialogOpen(false);
    };

    const handleTypeChange = (event) => {
        const selectedValue = event.target.value;
        const selectedOption = optionTypes.find(option => option.value === selectedValue);
        setEditingRow({
            ...editingRow,
            type: {
                _id: selectedOption.value,
                name: selectedOption.label
            }
        });
    };

    const handleVarietyChange = (event) => {
        const selectedValues = event.target.value;
        setEditingRow({
            ...editingRow,
            variety: selectedValues.map((value) => {
                const selectedOption = optionVarieties.find(option => option.value === value);
                return { name: selectedOption?.label || '' };
            }),
        });
    };

    const handleCountryChange = (event) => {
        const selectedValue = event.target.value;
        const selectedOption = optionCountries.find(option => option.value === selectedValue);
        setEditingRow({
            ...editingRow,
            country: {
                _id: selectedOption.value,
                name: selectedOption.label
            }
        });
    };

    const handleProcessingChange = (event) => {
        const selectedValue = event.target.value;
        const selectedOption = optionProcesses.find(option => option.value === selectedValue);
        setEditingRow({
            ...editingRow,
            processing: {
                _id: selectedOption.value,
                name: selectedOption.label
            }
        });
    };

    const handleAltitudeChange = (event) => {
        const value = event.target.value;

        if (value === '' || /^\d+$/.test(value)) {
            const numericValue = value === '' ? '' : parseInt(value, 10);
            if (value === '' || ( numericValue > 0 && numericValue <= 8000 )) {
                setEditingRow({ ...editingRow, altitude: value });
            }
        }
    };

    const columns = [
        { field: '_id', headerName: 'ID', width: 70, valueGetter: (params) => params.slice(-5) },
        {
            field: 'arrivalDate',
            headerName: 'Arrival Date',
            width: 130,
            valueFormatter: (value) => {
                if (isISO8601(value)) {
                    return formatISOToCustom(value);
                }
                return value;
            }
        },
        { field: 'lotNumber', headerName: 'Lot Number', width: 140 },
        { field: 'country', headerName: 'Country', width: 120, valueGetter: (params) => params?.name },
        { field: 'region', headerName: 'Region', width: 150 },
        { field: 'producerName', headerName: 'Producer Name', width: 150 },
        { field: 'harvest', headerName: 'Harvest', width: 135 },
        { field: 'type', headerName: 'Type', width: 100, valueGetter: (params) => params?.name },
        {
            field: 'variety',
            headerName: 'Variety',
            width: 180,
            valueGetter: (params) => {
                if (params.length === 0) {
                    return '';
                }
                return params.map(v => v?.name);
            }
        },
        { field: 'processing', headerName: 'Processing', width: 150, valueGetter: (params) => params?.name?.en },
        {
            field: 'qGrade',
            type: 'number',
            headerName: 'Q-Grade',
            width: 70,
            valueGetter: (params) => {
                if (params === 0) {
                    return '';
                }
                return params;
            }
        },
        {
            field: 'adjustedWeight',
            type: 'number',
            headerName: 'Weight',
            width: 80,
            valueGetter: (params) => {
                if (params === 0) {
                    return '';
                }
                return params;
            }
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 90,
            renderCell: (params) => (
                <TypographyBagStatus status={params.value} sx={{ height: '100%', alignContent: 'center' }}/>
            )
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: '',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ row }) => {
                return [
                    <GridActionsCellItem
                        icon={<Edit/>}
                        label="Edit"
                        onClick={() => handleEdit(row)}
                    />,
                    <GridActionsCellItem
                        icon={<Delete/>}
                        label="Delete"
                        onClick={() => handleDelete(row._id)}
                    />,
                ];
            }
        }
    ];

    return (
        <>
            <Toolbar/>
            <Container maxWidth="false" sx={{ mt: 4, mb: 4 }}>
                <Box
                    sx={{
                        height: '80vh',
                        width: '100%',
                    }}
                >
                    <DataGrid
                        rows={data || []}
                        columns={columns}
                        loading={isLoading}
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        pageSizeOptions={[5, 10, 15]}
                        getRowId={(row) => row._id}
                    />
                </Box>
            </Container>

            {/* Edit Dialog */}
            <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Edit Bag</DialogTitle>
                <DialogContent>
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Arrival Date"
                        value={editingRow?.arrivalDate || ''}
                        onChange={(e) => setEditingRow({ ...editingRow, arrivalDate: e.target.value })}
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Lot Number"
                        value={editingRow?.lotNumber || ''}
                        onChange={(e) => setEditingRow({ ...editingRow, lotNumber: e.target.value })}
                    />
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="select-country-label">Country</InputLabel>
                        <Select
                            variant="outlined"
                            labelId="select-country-label"
                            label="Country"
                            id="select-country"
                            value={editingRow?.country?._id || ''}
                            onChange={handleCountryChange}
                        >
                            {optionCountries.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Region"
                        value={editingRow?.region || ''}
                        onChange={(e) => setEditingRow({ ...editingRow, region: e.target.value })}
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Producer Name"
                        value={editingRow?.producerName || ''}
                        onChange={(e) => setEditingRow({ ...editingRow, producerName: e.target.value })}
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Altitude"
                        value={editingRow?.altitude || ''}
                        onChange={handleAltitudeChange}
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Q-Grade"
                        type="number"
                        value={editingRow?.qGrade || ''}
                        onChange={(e) => setEditingRow({ ...editingRow, qGrade: +e.target.value })}
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Harvest"
                        value={editingRow?.harvest || ''}
                        onChange={(e) => setEditingRow({ ...editingRow, harvest: e.target.value })}
                    />
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="select-type-label">Type</InputLabel>
                        <Select
                            variant="outlined"
                            labelId="select-type-label"
                            label="Type"
                            id="select-type"
                            value={editingRow?.type?._id || ''}
                            onChange={handleTypeChange}
                        >
                            {optionTypes.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="variety-select-label">Variety</InputLabel>
                        <Select
                            labelId="variety-select-label"
                            id="variety-select"
                            multiple
                            value={editingRow?.variety?.map((v) => optionVarieties.find(option => option.label === v.name)?.value) || []}
                            onChange={handleVarietyChange}
                            input={<OutlinedInput id="select-multiple-variety" label="Variety"/>}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((selectedItem) => {
                                        const selectedOption = optionVarieties.find(option => option.value === selectedItem);
                                        return (
                                            <Chip key={selectedItem} label={selectedOption?.label}/>
                                        );
                                    })}
                                </Box>
                            )}
                            variant="outlined">
                            {optionVarieties.map((item) => (
                                <MenuItem
                                    key={item.value}
                                    value={item.value}
                                    style={{
                                        fontWeight: editingRow?.variety?.some((v) => v.name === item.label)
                                            ? theme.typography.fontWeightBold
                                            : theme.typography.fontWeightRegular,
                                    }}
                                >
                                    {item.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="select-processing-label">Processing</InputLabel>
                        <Select
                            variant="outlined"
                            labelId="select-processing-label"
                            label="Processing"
                            id="select-processing"
                            value={editingRow?.processing?._id || ''}
                            onChange={handleProcessingChange}
                        >
                            {optionProcesses.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Descriptors"
                        multiline
                        value={editingRow?.descriptors || ''}
                        onChange={(e) => setEditingRow({ ...editingRow, descriptors: e.target.value })}
                        minRows={1}
                        maxRows={10}
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Weight (kg)"
                        type="number"
                        value={editingRow?.weight || ''}
                        onChange={(e) => setEditingRow({ ...editingRow, weight: +e.target.value })}
                    />
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Status</InputLabel>
                        <Select
                            variant="outlined"
                            value={editingRow?.status || ''}
                            onChange={(e) => setEditingRow({ ...editingRow, status: e.target.value })}
                            label="Status"
                        >
                            {BAG_STATUSES_ARR.map((status) => (
                                <MenuItem key={status} value={status}>
                                    {status.charAt(0).toUpperCase() + status.slice(1).replace('_', ' ')}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleSave} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
