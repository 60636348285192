export const AxiosErrorAlertHandler = (error, alertRef) => {
    if (error?.response) {
        alertRef.current.handleOpen('error', error?.response?.data?.error?.message);
    } else if (error?.request) {
        alertRef.current.handleOpen('error', `No response: ${error?.request}`);
    } else {
        console.warn(error);
        alertRef.current.handleOpen('error', 'Unidentified error.');
    }
};

export const formatISOToCustom = (dateString) => {
    const date = new Date(dateString); // Преобразуем строку в объект Date

    // Извлекаем компоненты даты и времени
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    // Форматируем в "dd.mm.yyyy hh:mm"
    return `${day}.${month}.${year} ${hours}:${minutes}`;
};

export const isISO8601 = (dateString) => {
    const iso8601Regex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(\.\d+)?Z$/;
    return iso8601Regex.test(dateString);
};