import React, { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    IconButton,
    Toolbar,
    Container,
    Paper,
} from '@mui/material';
import { Add, Edit, Delete } from '@mui/icons-material';
import axios from '@shared/config/axios';

export const ProcessesPage = () => {
    const queryClient = useQueryClient();
    const [open, setOpen] = useState(false);
    const [editData, setEditData] = useState(null);
    const [formData, setFormData] = useState({ name: { ua: '', en: '' } });

    const { data: processes = [], isLoading } = useQuery({
        queryKey: ['processes'],
        queryFn: async () => {
            const { data } = await axios.get('/processes');
            return data;
        },
    });

    const addMutation = useMutation({
        mutationFn: (newProcess) => axios.post('/processes', newProcess),
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ['processes'] }),
    });

    const updateMutation = useMutation({
        mutationFn: (updatedProcess) =>
            axios.patch(`/processes/${updatedProcess._id}`, {
                name: {
                    ua: updatedProcess.name.ua,
                    en: updatedProcess.name.en,
                },
            }),
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ['processes'] }),
    });

    const deleteMutation = useMutation({
        mutationFn: (id) => axios.delete(`/processes/${id}`),
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ['processes'] }),
    });

    const handleOpen = (data = null) => {
        setEditData(data);
        setFormData(data ? { name: { ...data.name } } : { name: { ua: '', en: '' } });
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setFormData({ name: { ua: '', en: '' } });
    };

    const handleSubmit = () => {
        if (editData) {
            updateMutation.mutate({ ...formData, _id: editData._id });
        } else {
            addMutation.mutate(formData);
        }
        handleClose();
    };

    const handleDelete = (id) => {
        deleteMutation.mutate(id);
    };

    if (isLoading) return <div>Loading...</div>;

    return (
        <>
            <Toolbar />
            <Container maxWidth="false" sx={{ mt: 4, mb: 4 }}>
                <Paper
                    sx={{
                        height: '80vh',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Box
                        sx={{
                            padding: 2,
                            borderBottom: '1px solid #ccc',
                        }}
                    >
                        <Button
                            startIcon={<Add />}
                            variant="contained"
                            color="primary"
                            onClick={() => handleOpen()}
                        >
                            Add Processing
                        </Button>
                    </Box>

                    <Box
                        sx={{
                            flex: 1,
                            overflowY: 'auto',
                        }}
                    >
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        sx={{
                                            position: 'sticky',
                                            top: 0,
                                            backgroundColor: 'background.paper',
                                            zIndex: 1,
                                        }}
                                    >
                                        ID
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            position: 'sticky',
                                            top: 0,
                                            backgroundColor: 'background.paper',
                                            zIndex: 1,
                                        }}
                                    >
                                        Name (UA)
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            position: 'sticky',
                                            top: 0,
                                            backgroundColor: 'background.paper',
                                            zIndex: 1,
                                        }}
                                    >
                                        Name (EN)
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            position: 'sticky',
                                            top: 0,
                                            backgroundColor: 'background.paper',
                                            zIndex: 1,
                                        }}
                                    >
                                        Actions
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {processes?.map((process) => (
                                    <TableRow key={process._id}>
                                        <TableCell>{process._id}</TableCell>
                                        <TableCell>{process.name.ua}</TableCell>
                                        <TableCell>{process.name.en}</TableCell>
                                        <TableCell>
                                            <IconButton color="primary" onClick={() => handleOpen(process)}>
                                                <Edit />
                                            </IconButton>
                                            <IconButton color="error" onClick={() => handleDelete(process._id)}>
                                                <Delete />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                </Paper>

                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>{editData ? 'Edit Processing' : 'Add Processing'}</DialogTitle>
                    <DialogContent>
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Name (UA)"
                            value={formData.name.ua}
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    name: { ...formData.name, ua: e.target.value },
                                })
                            }
                        />
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Name (EN)"
                            value={formData.name.en}
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    name: { ...formData.name, en: e.target.value },
                                })
                            }
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={handleSubmit} color="primary" variant="contained">
                            {editData ? 'Update' : 'Add'}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </>
    );
};
