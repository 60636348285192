import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '@providers/context/AuthContext';

export const RoleBasedRoute = ({ requiredRoles = [] }) => {
    const { isAuth, userInfo } = useAuth();
    const userRole = userInfo?.accountType.systemName;

    if (!isAuth) {
        return <Navigate to="/login" replace/>;
    }

    if (!requiredRoles.includes(userRole)) {
        return <Navigate to="/unauthorized" replace/>;
    }

    return <Outlet/>;
};
