import { useEffect, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import axios from '@shared/config/axios';
import { BAG_STATUSES } from '@shared/api/constants';
import { AxiosErrorAlertHandler } from '@shared/api/utils';

const bagDataInitial = {
    '_id': '',
    'arrivalDate': '',
    'harvest': '',
    'weight': '' || 0,
    'adjustedWeight': '' || 0,
    'lotNumber': '',
    'country': {
        '_id': '',
        'name': ''
    },
    'type': {
        '_id': '',
        'name': ''
    },
    'variety': [{
        '_id': '',
        'name': ''
    }],
    'descriptors': '',
    'stack': {
        '_id': '',
        'count': 0
    },
    'status': '',
    '__v': 0
};

export const useBag = (bagId) => {
    const alertRef = useRef();

    const [bagData, setBagData] = useState(bagDataInitial);
    const [loading, setLoading] = useState(false);
    const [bucket, setBucket] = useState('');
    const [weightValue, setWeightValue] = useState('');
    const [weightRoast, setWeightRoast] = useState('');
    const [roastType, setRoastType] = useState('');
    const [optionRoastTypes, setOptionRoastTypes] = useState([]);

    const { isLoading, data, refetch } = useQuery({
        queryKey: ['bag', bagId],
        queryFn: () => {
            return axios.get(`/bags/${bagId}`)
                .then(({ data }) => {
                    setBagData(data);
                    return data;
                });
        },
        refetchOnWindowFocus: true,
        retry: false,
    });
    useEffect(() => {
        if (data) {
            setBagData(data);
        }
    }, [data]);

    const { data: roastTypes } = useQuery({
        queryKey: ['roastTypes'],
        queryFn: () => {
            return axios.get(`/roast-types`).then((data) => data);
        }
    });
    useEffect(() => {
        if (roastTypes) {
            const newRoastTypes = roastTypes.data.map((item) => ( {
                label: item.name.en,
                value: item._id
            } ));
            setOptionRoastTypes(newRoastTypes);
        }
    }, [roastTypes]);

    const handleWeightChange = (event) => {
        const value = event.target.value;
        if (/^\d*\.?\d*$/.test(value)) {
            setWeightValue(value);
        }
    };
    const handleRoastTypeChange = (event) => {
        const selectedValue = event.target.value;
        const selectedOption = optionRoastTypes.find(option => option.value === selectedValue);
        setRoastType(selectedOption);
    };

    const handleWeightRoastChange = (event) => {
        const value = event.target.value;
        if (/^\d*\.?\d*$/.test(value)) {
            setWeightRoast(value);
        }
    };

    const handleBucketChange = (event) => {
        setBucket(event.target.value);
    };

    const pushWeight = async () => {
        try {
            setLoading(true);
            await axios.patch(`/bags/${bagData?._id}`, {
                weight: parseFloat(weightValue),
                status: BAG_STATUSES.IN_STOCK
            });
            setWeightValue('');
            alertRef.current.handleOpen('success', 'Weight added successfully.');
            refetch();
        } catch (error) {
            AxiosErrorAlertHandler(error, alertRef);
        } finally {
            setLoading(false);
        }
    };

    const toggleWeightRoast = async () => {
        try {
            setLoading(true);

            if (weightRoast > bagData?.adjustedWeight) {
                alertRef.current.handleOpen('warning', 'You can\'t take more weight than the actual one!');
                return;
            }

            const updatedData = {
                arrivalDate: ( new Date() ).toISOString(),
                roastType: roastType ? roastType.value : '',
                bag: bagData?._id,
                bucketId: bucket,
                weightBefore: parseFloat(weightRoast),
                firstGet: bagData?.status === BAG_STATUSES.IN_STOCK
            };

            await axios.post('/batches', updatedData);
            setWeightRoast('');
            setBucket('');
            setRoastType('');
            alertRef.current.handleOpen('success', 'Batch initialized successfully.');
            refetch();
        } catch (error) {
            AxiosErrorAlertHandler(error, alertRef);
        } finally {
            setLoading(false);
        }
    };

    const toggleFinished = async () => {
        try {
            setLoading(true);
            await axios.patch(`/bags/${bagData?._id}`, { status: BAG_STATUSES.FINISHED });
            alertRef.current.handleOpen('success', 'Bag is successfully completed.');
            refetch();
        } catch (error) {
            AxiosErrorAlertHandler(error, alertRef);
        } finally {
            setLoading(false);
        }
    };

    return {
        alertRef,
        bagData, loading, isLoading,
        toggleFinished,
        bucket, handleBucketChange,
        weightValue, handleWeightChange, pushWeight,
        weightRoast, handleWeightRoastChange, toggleWeightRoast,
        roastType, optionRoastTypes, handleRoastTypeChange
    };
};
