import {
    Button,
    Container, FormControl, Grid,
    Paper, TextField,
    Toolbar, Typography
} from '@mui/material';
import { Alert } from '@features/alert';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TypographyBatchStatus } from '@features/typography-colored-status-batch';
import { useQuery } from '@tanstack/react-query';
import axios from '@shared/config/axios';
import { BATCH_STATUSES } from '@shared/api/constants';
import { useAuth } from '@providers/context/AuthContext';
import { AxiosErrorAlertHandler, formatISOToCustom, isISO8601 } from '@shared/api/utils';


export const BucketPage = () => {
    const { id: bucketId } = useParams();
    const alertRef = useRef();
    const { userInfo } = useAuth();
    const navigate = useNavigate();

    const [batchData, setBatchData] = useState(null);
    const [weight, setWeight] = useState('');

    const handleWeightChange = (event) => {
        const value = event.target.value;
        if (/^\d*\.?\d*$/.test(value)) {
            setWeight(value);
        }
    };

    const toggleWeight = async () => {
        try {
            await axios.patch(`/batches/${batchData?._id}`, {
                weightAfter: parseFloat(weight),
                status: BATCH_STATUSES.IN_STOCK
            });
            setWeight('');
            alertRef.current.handleOpen('success', 'Weight after added successfully.');
            refetch();
        } catch (error) {
            AxiosErrorAlertHandler(error, alertRef);
        }
    };

    const { data, refetch } = useQuery({
        queryKey: ['bucket_batch', bucketId],
        queryFn: () => axios.get(`/users/${userInfo?._id}/batches/`, {
            params: {
                bucketId,
                status: BATCH_STATUSES.COMPLETED
            }
        }).then(({ data }) => data).catch(error => AxiosErrorAlertHandler(error, alertRef)),
        refetchOnWindowFocus: true,
        retry: false,
    });

    useEffect(() => {
        console.log(data);
        if (data) {
            if (data?.length === 0) {
                alertRef.current.handleOpen('error', 'This bucket is empty!');
                navigate('/batches-completed', { replace: true });
            } else {
                setBatchData(data[0]);
            }
        }
        // eslint-disable-next-line
    }, [data]);

    return (
        <>
            <Toolbar/>
            <Container
                maxWidth="sm"
                sx={{
                    marginTop: 4,
                    marginBottom: 4,
                }}
            >
                <Paper sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 'auto'
                }}>
                    <div>
                        <Typography variant="h5">Batch Information</Typography>
                        <Typography variant="body1">Bucket ID: {batchData?.bucketId}</Typography>
                        <Typography variant="body1">
                            {batchData?.arrivalDate
                                ? isISO8601(batchData.arrivalDate)
                                    ? formatISOToCustom(batchData.arrivalDate)
                                    : batchData.arrivalDate
                                : ''
                            }
                        </Typography>
                        <Typography variant="body1">{batchData?.bag?.country?.name}</Typography>
                        <TypographyBatchStatus status={batchData?.status} variant="body1"/>
                        <Grid item xs={12} container direction="row">
                            <Grid item xs={6}>
                                <Typography variant="body1">Before: {batchData?.weightBefore} kg</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body1">{batchData?.roastType?.name?.en}</Typography>
                            </Grid>
                        </Grid>
                    </div>
                    {!batchData?.weightAfter ? (
                        <>
                            <Grid item xs={12} sm={8} container direction="row" spacing={1} sx={{ mt: 2 }}>
                                <Grid item xs={12} sm={4}>
                                    <FormControl>
                                        <TextField
                                            label="Weight, kg"
                                            size="small"
                                            variant="outlined"
                                            type="text"
                                            value={weight}
                                            onChange={handleWeightChange}
                                            fullWidth
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="medium"
                                        onClick={toggleWeight}
                                        disabled={!weight}
                                    >
                                        Set Weight After
                                    </Button>
                                </Grid>
                            </Grid>
                        </> ) : ( <>
                        <Typography variant="body1">After: {batchData.weightAfter} kg</Typography>
                    </> )}
                    {batchData?.adjustedWeight ? (
                        <Typography variant="body1" sx={{ color: 'success.main', alignSelf: 'center' }}>Adjusted
                            Weight: {batchData?.adjustedWeight} kg</Typography>
                    ) : ( <></> )}
                </Paper>
            </Container>
            <Alert ref={alertRef}/>
        </>
    );
};
