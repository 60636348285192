import React, { useRef, useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    IconButton,
    Toolbar,
    Container,
    Paper,
    Switch,
    MenuItem,
    Select,
} from '@mui/material';
import { Add, Edit, Delete, Lock } from '@mui/icons-material';
import axios from '@shared/config/axios';
import { Alert } from '@features/alert';

export const UsersPage = () => {
    const alertRef = useRef();

    const queryClient = useQueryClient();
    const [open, setOpen] = useState(false);
    const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [formData, setFormData] = useState({
        login: '',
        firstName: '',
        lastName: '',
        accountType: '',
        isDisabled: false,
    });
    const [newPassword, setNewPassword] = useState('');

    // Fetch users and account types
    const { data: users = [], isLoading: usersLoading } = useQuery({
        queryKey: ['users'],
        queryFn: async () => {
            const { data } = await axios.get('/users');
            return data;
        }
    });

    const { data: accountTypes = [] } = useQuery({
        queryKey: ['accountTypes'],
        queryFn: async () => {
            const { data } = await axios.get('/account-types');
            return data;
        }
    });

    // Mutations
    const addMutation = useMutation({
        mutationFn: (newUser) => axios.post('/users/sign-up', newUser),
        onSuccess: () => queryClient.invalidateQueries(['users']),
    });

    const updateMutation = useMutation({
        mutationFn: (updatedUser) =>
            axios.patch(`/users/${updatedUser._id}`, {
                login: updatedUser.login,
                firstName: updatedUser.firstName,
                lastName: updatedUser.lastName,
                accountType: updatedUser.accountType,
                isDisabled: updatedUser.isDisabled,
            }),
        onSuccess: () => queryClient.invalidateQueries(['users']),
    });

    const deleteMutation = useMutation({
        mutationFn: (id) => axios.delete(`/users/${id}`),
        onSuccess: () => queryClient.invalidateQueries(['users']),
    });

    const resetPasswordMutation = useMutation({
        mutationFn: ({ userId, newPassword }) =>
            axios.post('/users/reset-password', { userId: userId, newPassword }),
        onSuccess: () => {
            queryClient.invalidateQueries(['users']);
            setPasswordDialogOpen(false);
        },
    });

    // Dialog handlers
    const handleOpen = (user = null) => {
        setSelectedUser(user);
        setFormData(
            user
                ? {
                    login: user.login,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    accountType: user.accountType?._id || '',
                    isDisabled: user.isDisabled,
                }
                : {
                    login: '',
                    firstName: '',
                    lastName: '',
                    accountType: '',
                    isDisabled: false,
                }
        );
        setOpen(true);
    };

    const handlePasswordDialogOpen = (user) => {
        setSelectedUser(user);
        setPasswordDialogOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setFormData({
            login: '',
            firstName: '',
            lastName: '',
            accountType: '',
            isDisabled: false,
        });
    };

    const handlePasswordDialogClose = () => {
        setPasswordDialogOpen(false);
        setNewPassword('');
    };

    const handleSubmit = () => {
        if (selectedUser) {
            updateMutation.mutate({ ...formData, _id: selectedUser._id });
        } else {
            addMutation.mutate(formData);
        }
        handleClose();
    };

    const handleDelete = (id) => {
        deleteMutation.mutate(id);
    };

    const handleResetPassword = () => {
        if (newPassword) {
            resetPasswordMutation.mutate({
                userId: selectedUser._id,
                newPassword,
            });
            alertRef.current.handleOpen('success', 'Password update successfully.');
        }
    };

    if (usersLoading) return <div>Loading...</div>;

    return (
        <>
            <Toolbar/>
            <Container maxWidth="false" sx={{ mt: 4, mb: 4 }}>
                <Paper
                    sx={{
                        height: '80vh',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Box sx={{ padding: 2, borderBottom: '1px solid #ccc' }}>
                        <Button
                            startIcon={<Add/>}
                            variant="contained"
                            color="primary"
                            onClick={() => handleOpen()}
                        >
                            Add User
                        </Button>
                    </Box>
                    <Box sx={{ flex: 1, overflowY: 'auto' }}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Login</TableCell>
                                    <TableCell>First Name</TableCell>
                                    <TableCell>Last Name</TableCell>
                                    <TableCell>Account Type</TableCell>
                                    <TableCell>Disabled</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {users.map((user) => (
                                    <TableRow key={user._id}>
                                        <TableCell>{user._id}</TableCell>
                                        <TableCell>{user.login}</TableCell>
                                        <TableCell>{user.firstName}</TableCell>
                                        <TableCell>{user.lastName}</TableCell>
                                        <TableCell>{user.accountType?.name || 'N/A'}</TableCell>
                                        <TableCell>
                                            <Switch
                                                checked={!user.isDisabled}
                                                onChange={() =>
                                                    updateMutation.mutate({
                                                        ...user,
                                                        accountType: user.accountType._id,
                                                        isDisabled: !user.isDisabled,
                                                    })
                                                }
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <IconButton color="primary" onClick={() => handleOpen(user)}>
                                                <Edit/>
                                            </IconButton>
                                            <IconButton
                                                color="error"
                                                onClick={() => handleDelete(user._id)}
                                            >
                                                <Delete/>
                                            </IconButton>
                                            <IconButton
                                                color="secondary"
                                                onClick={() => handlePasswordDialogOpen(user)}
                                            >
                                                <Lock/>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                </Paper>

                {/* User Dialog */}
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>{selectedUser ? 'Edit User' : 'Add User'}</DialogTitle>
                    <DialogContent>
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Login"
                            value={formData.login}
                            onChange={(e) => setFormData({ ...formData, login: e.target.value })}
                        />
                        {/* Поле пароля отображается только для нового пользователя */}
                        {!selectedUser && (
                            <TextField
                                fullWidth
                                margin="normal"
                                type="password"
                                label="Password"
                                value={formData.password}
                                onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                            />
                        )}
                        <TextField
                            fullWidth
                            margin="normal"
                            label="First Name"
                            value={formData.firstName}
                            onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
                        />
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Last Name"
                            value={formData.lastName}
                            onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
                        />
                        <Select
                            fullWidth
                            variant="outlined"
                            sx={{ mt: 2 }}
                            value={formData.accountType}
                            onChange={(e) =>
                                setFormData({ ...formData, accountType: e.target.value })
                            }
                        >
                            {accountTypes.map((type) => (
                                <MenuItem key={type._id} value={type._id}>
                                    {type.name}
                                </MenuItem>
                            ))}
                        </Select>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={handleSubmit} color="primary" variant="contained">
                            {selectedUser ? 'Update' : 'Add'}
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Password Reset Dialog */}
                <Dialog open={passwordDialogOpen} onClose={handlePasswordDialogClose}>
                    <DialogTitle>Reset Password</DialogTitle>
                    <DialogContent>
                        <TextField
                            fullWidth
                            margin="normal"
                            type="password"
                            label="New Password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handlePasswordDialogClose} color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={handleResetPassword} color="primary" variant="contained">
                            Reset
                        </Button>
                    </DialogActions>
                </Dialog>
                <Alert ref={alertRef}/>
            </Container>
        </>
    );
};
