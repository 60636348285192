import { Toolbar, Container } from '@mui/material';


export const HomePage = () => {
    return (
        <>
            <Toolbar/>
            <Container maxWidth="false" sx={{ mt: 4, mb: 4, height: '88vh' }}>
            </Container>
        </>
    );
};