import axios from 'axios';


const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

instance.interceptors.request.use((config) => {
    config.headers['Authentication-Token'] = JSON.parse(window.localStorage.getItem('user-auth-token'))?.authToken || null;
    return config;
});

export default instance;