import { useEffect, useState } from 'react';
import { Toolbar, Container, Paper } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { GrainArrivalChart } from '@widgets/linear-chart';
import axios from '@shared/config/axios';


export const AdminDashboardPage = () => {
    const [bagData, setBagData] = useState([]);

    const { data } = useQuery({
        queryKey: ['bag'],
        queryFn: () => {
            return axios.get(`/bags`)
                .then(({ data }) => {
                    setBagData(data);
                    return data;
                });
        },
        refetchOnWindowFocus: true,
        retry: false,
    });

    useEffect(() => {
        if (data) {
            setBagData(data);
        }
    }, [data]);


    return (
        <>
            <Toolbar/>
            <Container maxWidth="false" sx={{ mt: 4, mb: 4, height: '88vh' }}>
                <Paper sx={{ width: 1000, alignItems: 'center', mb: 2 }}>
                    <GrainArrivalChart bagData={bagData}/>
                </Paper>
                {/*<Paper sx={{ width: 600, alignItems: 'center' }}>*/}
                {/*    <PageViewsBarChart/>*/}
                {/*</Paper>*/}
            </Container>
        </>
    );
};