import React, { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    IconButton,
    Toolbar,
    Container,
    Paper,
} from '@mui/material';
import { Add, Edit, Delete } from '@mui/icons-material';
import axios from '@shared/config/axios';

export const RoastTypesPage = () => {
    const queryClient = useQueryClient();
    const [open, setOpen] = useState(false);
    const [editData, setEditData] = useState(null);
    const [formData, setFormData] = useState({ name: { ua: '', en: '' } });

    const { data: roastTypes = [], isLoading } = useQuery({
        queryKey: ['roast-types'],
        queryFn: async () => {
            const { data } = await axios.get('/roast-types');
            return data;
        },
    });

    const addMutation = useMutation({
        mutationFn: (newType) => axios.post('/roast-types', newType),
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ['roast-types'] }),
    });

    const updateMutation = useMutation({
        mutationFn: (updatedType) =>
            axios.patch(`/roast-types/${updatedType._id}`, {
                name: {
                    ua: updatedType.name.ua,
                    en: updatedType.name.en
                },
            }),
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ['roast-types'] }),
    });

    const deleteMutation = useMutation({
        mutationFn: (id) => axios.delete(`/roast-types/${id}`),
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ['roast-types'] }),
    });

    const handleOpen = (data = null) => {
        setEditData(data);
        setFormData(data ? { name: { ...data.name } } : { name: { ua: '', en: '' } });
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setFormData({ name: { ua: '', en: '' } });
    };

    const handleSubmit = () => {
        if (editData) {
            updateMutation.mutate({ ...formData, _id: editData._id });
        } else {
            addMutation.mutate(formData);
        }
        handleClose();
    };

    const handleDelete = (id) => {
        deleteMutation.mutate(id);
    };

    if (isLoading) return <div>Loading...</div>;

    return (
        <>
            <Toolbar/>
            <Container maxWidth="false" sx={{ mt: 4, mb: 4 }}>
                <Paper
                    sx={{
                        height: '80vh', // Фиксированная высота
                        display: 'flex', // Flexbox
                        flexDirection: 'column', // Элементы по вертикали
                    }}
                >
                    {/* Верхняя часть с кнопкой */}
                    <Box
                        sx={{
                            padding: 2,
                            borderBottom: '1px solid #ccc',
                        }}
                    >
                        <Button
                            startIcon={<Add/>}
                            variant="contained"
                            color="primary"
                            onClick={() => handleOpen()}
                        >
                            Add Roast Type
                        </Button>
                    </Box>

                    {/* Нижняя часть с таблицей */}
                    <Box
                        sx={{
                            flex: 1, // Занимает оставшееся пространство
                            overflowY: 'auto', // Прокрутка для таблицы
                        }}
                    >
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        sx={{
                                            position: 'sticky',
                                            top: 0,
                                            backgroundColor: 'background.paper',
                                            zIndex: 1,
                                        }}
                                    >
                                        ID
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            position: 'sticky',
                                            top: 0,
                                            backgroundColor: 'background.paper',
                                            zIndex: 1,
                                        }}
                                    >
                                        Name (UA)
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            position: 'sticky',
                                            top: 0,
                                            backgroundColor: 'background.paper',
                                            zIndex: 1,
                                        }}
                                    >
                                        Name (EN)
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            position: 'sticky',
                                            top: 0,
                                            backgroundColor: 'background.paper',
                                            zIndex: 1,
                                        }}
                                    >
                                        Actions
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {roastTypes?.map((type) => (
                                    <TableRow key={type._id}>
                                        <TableCell>{type._id}</TableCell>
                                        <TableCell>{type.name.ua}</TableCell>
                                        <TableCell>{type.name.en}</TableCell>
                                        <TableCell>
                                            <IconButton color="primary" onClick={() => handleOpen(type)}>
                                                <Edit/>
                                            </IconButton>
                                            <IconButton color="error" onClick={() => handleDelete(type._id)}>
                                                <Delete/>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                </Paper>

                {/* Диалог для добавления/редактирования */}
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>{editData ? 'Edit Roast Type' : 'Add Roast Type'}</DialogTitle>
                    <DialogContent>
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Name (UA)"
                            value={formData.name.ua}
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    name: { ...formData.name, ua: e.target.value },
                                })
                            }
                        />
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Name (EN)"
                            value={formData.name.en}
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    name: { ...formData.name, en: e.target.value },
                                })
                            }
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={handleSubmit} color="primary" variant="contained">
                            {editData ? 'Update' : 'Add'}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </>
    );
};
