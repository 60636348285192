import React from 'react';
import { Navigate, useLocation, Outlet } from 'react-router-dom';

import { useAuth } from '@providers/context/AuthContext';
import { CircularProgress } from '@mui/material';


export const ProtectedRoute = () => {
    const location = useLocation();
    const { isAuth, status } = useAuth();

    if (status === 'pending') return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
        }}>
            <CircularProgress/>
        </div>
    );
    return isAuth ? <Outlet/> : <Navigate to="/login" state={{ from: location }} replace/>;

};
