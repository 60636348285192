import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Box, Card, CardContent, Container, Toolbar, Typography } from '@mui/material';
import axios from '@shared/config/axios';
import { PACK_STATUSES } from '@shared/api/constants';
import { Alert } from '@features/alert';

export const PacksViewPage = () => {
    const alertRef = useRef();

    const [packsData, setPacksData] = useState([]);

    const lastSelectedPackId = localStorage.getItem('lastSelectedPackId');

    const { data } = useQuery({
        queryKey: ['packs'],
        queryFn: () => {
            return axios.get(`/packs/`, { params: { status: PACK_STATUSES.INITIALIZED } }).then(({ data }) => data);
        },
        refetchOnWindowFocus: true,
        staleTime: 0,
        cacheTime: 0,
    });

    useEffect(() => {
        if (data) {
            const sortedData = lastSelectedPackId
                ? [
                    ...data.filter((pack) => pack._id === lastSelectedPackId),
                    ...data.filter((pack) => pack._id !== lastSelectedPackId),
                ]
                : data;
            setPacksData(sortedData);
        }
    }, [data, lastSelectedPackId]);

    const formatDate = (isoString) => {
        const date = new Date(isoString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${day}-${month}-${year}`;
    };


    return (
        <>
            <Toolbar/>
            <Container
                maxWidth={false}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    paddingTop: 2,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 3,
                        justifyContent: 'flex-start',
                        width: '100%',
                    }}
                >
                    {packsData.map((pack) => (
                        <Card
                            key={pack?._id}
                            component={Link}
                            to={`../packs/${pack?._id}`}
                            sx={{
                                textDecoration: 'none',
                                borderRadius: '12px',
                                width: '200px', // Фиксированная ширина
                                alignSelf: 'flex-start', // Не позволяет растягивать элементы
                                transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Плавные переходы
                                '&:hover': {
                                    transform: 'scale(1.05)', // Эффект увеличения при наведении
                                    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)', // Увеличение тени при наведении
                                },
                            }}
                        >
                            <CardContent
                                sx={{
                                    padding: '16px',
                                    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)', // Базовая тень
                                    borderRadius: '12px', // Закругленные углы
                                    background: 'linear-gradient(135deg,  #fafafa, #f0f0f0)', // Легкий градиент
                                    '&:hover': {
                                        background: 'linear-gradient(135deg, #ffffff, #f0f0f0)', // Изменение фона при наведении
                                    },
                                }}
                            >
                                <Box display="flex" justifyContent="space-between"
                                     sx={{ borderBottom: '1px solid #ccc', pb: 2 }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                        {pack?.country?.name} {pack?.producerName}
                                    </Typography>
                                </Box>
                                <Box display="flex" justifyContent="space-between" sx={{ pt: 2 }}>
                                    <Typography variant="body1" color="secondary.main">
                                        {pack?.roastType?.name?.en}
                                    </Typography>
                                    <Typography variant="body1">{pack?.weight} kg</Typography>
                                </Box>
                                <Box display="flex" justifyContent="space-between">
                                    <Typography variant="body2" sx={{ pt: 4, color: 'text.secondary' }}>
                                        {pack?.orderDate ? formatDate(pack.orderDate) : ''}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        color="success.main"
                                        sx={{
                                            pt: 4,
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {pack?._id === lastSelectedPackId ? 'last' : ''}
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Card>

                    ))}
                </Box>
            </Container>
            <Alert ref={alertRef}/>
        </>
    );
};
