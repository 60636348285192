import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Box,
    Button,
    Divider,
    IconButton,
    List,
    Toolbar,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import { UserListItems, ConstantListItems, DataListItems, MainListItems } from './admin-list-items';
import { useAuth } from '@providers/context/AuthContext';
import Stack from '@mui/material/Stack';


const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ( {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...( open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    } ),
} ));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ( {
    '& .MuiDrawer-paper': {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        backgroundColor: '#F5F5F5',
        boxShadow: 12,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: 'border-box',
        ...( !open && {
            overflowX: 'hidden',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(7),
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(8.5),
            },
        } ),
    },
} ),);

export const AdminHeader = () => {
    const location = useLocation();
    const { logout, userInfo } = useAuth();
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(true);

    const toggleDrawer = () => {
        setOpen(!open);
    };

    const onClickLogout = async () => {
        const data = await logout.mutateAsync();
        if (data) navigate('/login');

    };

    const getPageTitle = (pathname) => {
        const routesMap = {
            '/admin': 'Dashboard',
            '/admin/registration': 'Registration',
            '/admin/users': 'Users',
            '/admin/bags': 'Bags',
            '/admin/batches': 'Batches',
            '/admin/orders': 'Orders',
            '/admin/packs': 'Packs',
            '/admin/countries': 'Countries',
            '/admin/processes': 'Processes',
            '/admin/roast-types': 'Roast Types',
            '/admin/types': 'Types',
            '/admin/varieties': 'Varieties',
        };

        // Функция для проверки динамических сегментов пути (например, /batches/:id)
        const getDynamicRouteTitle = (path) => {
            const dynamicRoutes = [
                { pattern: /^\/admin\/batches\/[^/]+$/, title: 'Batch Details' },
                { pattern: /^\/admin\/bags\/[^/]+$/, title: 'Bag Details' },
                { pattern: /^\/admin\/packs\/[^/]+$/, title: 'Pack Details' },
                { pattern: /^\/admin\/orders\/[^/]+$/, title: 'Order Details' }
            ];

            for (const route of dynamicRoutes) {
                if (route.pattern.test(path)) {
                    return route.title;
                }
            }

            return null;
        };

        // Проверка точных совпадений
        if (routesMap[pathname]) {
            return routesMap[pathname];
        }

        // Проверка динамических маршрутов
        const dynamicTitle = getDynamicRouteTitle(pathname);
        if (dynamicTitle) {
            return dynamicTitle;
        }

        // Если маршрут не найден, вернуть "Unknown Page"
        return 'Unknown Page';
    };

    const namePage = getPageTitle(location.pathname);

    return (
        <>
            <AppBar position="absolute" open={open}>
                <Toolbar sx={{ pr: '24px' }}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        sx={{
                            marginRight: '36px',
                            ...( open && { display: 'none' } ),
                        }}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1 }}
                    >
                        {namePage}
                    </Typography>
                    <Button onClick={() => navigate('/')} variant="contained" color="info" sx={{ mr: 2 }}>
                        User Pages
                    </Button>
                    <Button onClick={onClickLogout} variant="contained" color="error">
                        Log out
                    </Button>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                open={open}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    height: '100vh'
                }}
            >
                <Box sx={{ flexGrow: 1 }}>
                    <Toolbar
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            px: [1],
                        }}
                    >
                        <IconButton onClick={toggleDrawer}>
                            <ChevronLeftIcon/>
                        </IconButton>
                    </Toolbar>
                    <Divider/>
                    <List component="nav" sx={{ ml: 0.5 }}>
                        <MainListItems/>
                        <Divider textAlign="left">Users</Divider>
                        <UserListItems/>
                        <Divider sx={{ my: 1 }} textAlign="left">Data</Divider>
                        <DataListItems/>
                        <Divider sx={{ my: 1 }} textAlign="left">Const</Divider>
                        <ConstantListItems/>
                    </List>
                </Box>
                <Stack
                    direction="row"
                    sx={{
                        p: 2,
                        gap: 1,
                        alignItems: 'center',
                        borderTop: '1px solid',
                        borderColor: 'divider',
                    }}
                >
                    <Box sx={{ mr: 'auto' }}>
                        <Typography variant="h6" sx={{ fontWeight: 500, lineHeight: '16px' }}>
                            {userInfo?.firstName ? userInfo.firstName : ''} {userInfo?.lastName ? userInfo.lastName : ''}
                        </Typography>
                        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                            {userInfo?.accountType?.name ? userInfo.accountType.name : ''}
                        </Typography>
                    </Box>
                </Stack>
            </Drawer>
        </>
    );
};
