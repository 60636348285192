import React, { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    IconButton, Toolbar, Container, Paper
} from '@mui/material';
import { Add, Edit, Delete } from '@mui/icons-material';
import axios from '@shared/config/axios';


export const CountriesPage = () => {
    const queryClient = useQueryClient();
    const [open, setOpen] = useState(false);
    const [editData, setEditData] = useState(null);
    const [formData, setFormData] = useState({ name: '' });

    const { data: countries = [], isLoading } = useQuery({
        queryKey: ['countries'],
        queryFn: async () => {
            const { data } = await axios.get('/countries');
            return data;
        },
    });

    const addMutation = useMutation({
        mutationFn: (newCountry) => axios.post('/countries', newCountry),
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ['countries'] }),
    });

    const updateMutation = useMutation({
        mutationFn: (updatedCountry) => axios.patch(`/countries/${updatedCountry._id}`, { name: updatedCountry.name }),
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ['countries'] }),
    });

    const deleteMutation = useMutation({
        mutationFn: (id) => axios.delete(`/countries/${id}`),
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ['countries'] }),
    });

    const handleOpen = (data = null) => {
        setEditData(data);
        setFormData(data || { name: '' });
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setFormData({ name: '' });
    };

    const handleSubmit = () => {
        if (editData) {
            updateMutation.mutate({ ...formData, id: editData._id });
        } else {
            addMutation.mutate(formData);
        }
        handleClose();
    };

    const handleDelete = (id) => {
        deleteMutation.mutate(id);
    };

    if (isLoading) return <div>Loading...</div>;

    return (
        <>
            <Toolbar/>
            <Container maxWidth="false" sx={{ mt: 4, mb: 4 }}>
                <Paper
                    sx={{
                        height: '80vh', // Фиксированная высота Paper
                        display: 'flex', // Flexbox для разделения на части
                        flexDirection: 'column', // Элементы располагаются по вертикали
                    }}
                >
                    {/* Верхняя часть с кнопкой */}
                    <Box
                        sx={{
                            padding: 2,
                            borderBottom: '0.5px solid #ccc', // Разделитель для наглядности
                        }}
                    >
                        <Button
                            startIcon={<Add/>}
                            variant="contained"
                            color="primary"
                            onClick={() => handleOpen()}
                        >
                            Add Country
                        </Button>
                    </Box>

                    {/* Нижняя часть с таблицей */}
                    <Box
                        sx={{
                            flex: 1, // Занимает оставшееся пространство
                            overflowY: 'auto', // Прокрутка для таблицы
                        }}
                    >
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        sx={{
                                            position: 'sticky',
                                            top: 0,
                                            backgroundColor: 'background.paper', // Фон для фиксации
                                            zIndex: 1, // Приоритет отображения
                                        }}
                                    >
                                        ID
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            position: 'sticky',
                                            top: 0,
                                            backgroundColor: 'background.paper',
                                            zIndex: 1,
                                        }}
                                    >
                                        Name
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            position: 'sticky',
                                            top: 0,
                                            backgroundColor: 'background.paper',
                                            zIndex: 1,
                                        }}
                                    >
                                        Actions
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {countries?.map((country) => (
                                    <TableRow key={country._id}>
                                        <TableCell>{country._id}</TableCell>
                                        <TableCell>{country.name}</TableCell>
                                        <TableCell>
                                            <IconButton color="primary" onClick={() => handleOpen(country)}>
                                                <Edit/>
                                            </IconButton>
                                            <IconButton color="error" onClick={() => handleDelete(country._id)}>
                                                <Delete/>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                </Paper>
                {/* Dialog for Add/Edit */}
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>{editData ? 'Edit Country' : 'Add Country'}</DialogTitle>
                    <DialogContent>
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Name"
                            value={formData.name}
                            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={handleSubmit} color="primary" variant="contained">
                            {editData ? 'Update' : 'Add'}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </>
    );
};


