import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { LineChart } from '@mui/x-charts/LineChart';

function AreaGradient({ color, id }) {
    return (
        <defs>
            <linearGradient id={id} x1="50%" y1="0%" x2="50%" y2="100%">
                <stop offset="0%" stopColor={color} stopOpacity={0.5}/>
                <stop offset="100%" stopColor={color} stopOpacity={0}/>
            </linearGradient>
        </defs>
    );
}

AreaGradient.propTypes = {
    color: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
};

// Форматирует дату в 'yyyy-MM-dd' для удобства работы с датами
function formatDate(date) {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

// Функция для генерации всех дат в диапазоне
function getAllDatesInRange(startDate, endDate) {
    const dates = [];
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
        dates.push(formatDate(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
}

export const GrainArrivalChart = ({ bagData = [] }) => {
    const theme = useTheme();

    // Преобразуем все даты поступления зерна в формат 'yyyy-MM-dd'
    const arrivalDates = bagData.map(bag => {
        const [day, month, year] = bag.arrivalDate.split('.');
        return new Date(`${year}-${month}-${day}`);
    });

    // Находим самую раннюю и самую позднюю дату
    const minDate = new Date(Math.min(...arrivalDates));
    const maxDate = new Date(Math.max(...arrivalDates));

    // Получаем все даты в диапазоне
    const allDates = getAllDatesInRange(minDate, maxDate);

    // console.log(allDates);

    // Группируем вес по каждой дате
    const weightByDate = allDates.map(date => {
        const totalWeight = bagData
            .filter(bag => {
                const [day, month, year] = bag.arrivalDate.split('.');
                const formattedArrivalDate = `${year}-${month}-${day}`;
                return formattedArrivalDate === date;
            })
            .reduce((acc, bag) => {
                // Проверяем, есть ли поле weight и добавляем вес только если оно существует
                return acc + ( bag.weight || 0 );
            }, 0);
        return totalWeight;
    });
    // console.log(weightByDate);

    const colorPalette = [
        theme.palette.primary.light,
        theme.palette.primary.main,
        theme.palette.primary.dark,
    ];

    return (
        <Card variant="outlined" sx={{ width: '100%' }}>
            <CardContent>
                <Typography component="h2" variant="subtitle2" gutterBottom>
                    Grain Arrivals (All Time)
                </Typography>
                <LineChart
                    colors={colorPalette}
                    xAxis={[
                        {
                            scaleType: 'point',
                            data: allDates, // Используем все даты
                            // tickInterval: (index, i) => (i + 1) % Math.floor(allDates.length / 10) === 0, // Интервал для меток (каждые 10%)
                            tickInterval: (index, i) => ( i + 1 ) % 5 === 0,
                        },
                    ]}
                    series={[
                        {
                            id: 'weight',
                            label: 'Total Weight (kg)',
                            showMark: true,
                            curve: 'linear',
                            area: false,
                            stack: 'total',
                            stackOrder: 'ascending',
                            data: weightByDate, // Данные веса по датам
                        },
                    ]}
                    height={250}
                    margin={{ left: 50, right: 20, top: 20, bottom: 20 }}
                    grid={{ horizontal: true }}
                    slotProps={{
                        legend: {
                            hidden: true,
                        },
                    }}
                >
                    <AreaGradient color={theme.palette.primary.dark} id="weight"/>
                </LineChart>
            </CardContent>
        </Card>
    );
};
