import React from 'react';
import { Link } from 'react-router-dom';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import {
    Home,
    Person,
    LocalMall,
    Grain,
    Inventory2,
    LocalShipping,
    Public,
    Category,
    LocalFireDepartment,
    Storage,
    EmojiNature
} from '@mui/icons-material';

export const MainListItems = () => (
    <ListItemButton component={Link} to="../admin">
        <ListItemIcon>
            <Home color="primary"/>
        </ListItemIcon>
        <ListItemText primary="Home"/>
    </ListItemButton>
);

export const UserListItems = () => (
    <>
        <ListItemButton component={Link} to="../admin/users">
            <ListItemIcon>
                <Person color="primary"/>
            </ListItemIcon>
            <ListItemText primary="Users"/>
        </ListItemButton>
    </>
);

export const DataListItems = () => (
    <>
        <ListItemButton component={Link} to="../admin/bags">
            <ListItemIcon>
                <LocalMall color="primary"/>
            </ListItemIcon>
            <ListItemText primary="Bags"/>
        </ListItemButton>
        <ListItemButton component={Link} to="../admin/batches">
            <ListItemIcon>
                <Grain color="primary"/>
            </ListItemIcon>
            <ListItemText primary="Batches"/>
        </ListItemButton>
        <ListItemButton component={Link} disabled={true} to="../admin/orders">
            <ListItemIcon>
                <Inventory2 color="primary"/>
            </ListItemIcon>
            <ListItemText primary="Orders"/>
        </ListItemButton>
        <ListItemButton component={Link} to="../admin/packs">
            <ListItemIcon>
                <LocalShipping color="primary"/>
            </ListItemIcon>
            <ListItemText primary="Packs"/>
        </ListItemButton>
    </>
);

export const ConstantListItems = () => (
    <>
        <ListItemButton component={Link} to="../admin/countries">
            <ListItemIcon>
                <Public color="primary"/>
            </ListItemIcon>
            <ListItemText primary="Countries"/>
        </ListItemButton>
        <ListItemButton component={Link} to="../admin/processes">
            <ListItemIcon>
                <Category color="primary"/>
            </ListItemIcon>
            <ListItemText primary="Processes"/>
        </ListItemButton>
        <ListItemButton component={Link} to="../admin/roast-types">
            <ListItemIcon>
                <LocalFireDepartment color="primary"/>
            </ListItemIcon>
            <ListItemText primary="RoastTypes"/>
        </ListItemButton>
        <ListItemButton component={Link} to="../admin/types">
            <ListItemIcon>
                <Storage color="primary"/>
            </ListItemIcon>
            <ListItemText primary="Types"/>
        </ListItemButton>
        <ListItemButton component={Link} to="../admin/varieties">
            <ListItemIcon>
                <EmojiNature color="primary"/>
            </ListItemIcon>
            <ListItemText primary="Varieties"/>
        </ListItemButton>
    </>
);
