export const BAG_STATUSES_ARR = ['initialized', 'in_stock', 'roasting', 'finished'];

export const BAG_STATUSES = {
    INITIALIZED: 'initialized',
    IN_STOCK: 'in_stock',
    ROASTING: 'roasting',
    FINISHED: 'finished'
};

export const BATCH_STATUSES_ARR = ['initialized', 'in_progress', 'completed', 'in_stock', 'finished'];

export const BATCH_STATUSES = {
    INITIALIZED: 'initialized',
    IN_PROGRESS: 'in_progress',
    COMPLETED: 'completed',
    IN_STOCK: 'in_stock',
    FINISHED: 'finished'
};

export const USER_ROLES = {
    ADMIN: 'admin',
    ROASTER: 'roaster',
    PICKER: 'picker',
    INVENTOR: 'inventor',
};

export const BAG_MINIMAL_WEIGHT = 2;

export const INITIAL_VALUE_SELECT = {
    label: '',
    value: ''
};

export const PACK_STATUSES_ARR = ['initialized', 'in_progress', 'completed', 'finished'];

export const PACK_STATUSES = {
    INITIALIZED: 'initialized',
    IN_PROGRESS: 'in_progress',
    COMPLETED: 'completed',
    FINISHED: 'finished'
};