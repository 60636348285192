import {QRCodeSVG} from 'qrcode.react';
import {
    Toolbar,
    Container,
    Box,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import QrCodeIcon from '@mui/icons-material/QrCode';
import LaunchIcon from '@mui/icons-material/Launch';

import { useBags } from '../api/useBags';
import { TypographyBagStatus } from '@features/typography-colored-status-bag';
import { Alert } from '@features/alert';
import { formatISOToCustom, isISO8601 } from '@shared/api/utils';

export const BagsViewPage = () => {
    const {
        alertRef,
        rows,
        open,
        qrCodeValue,
        handleOpen,
        handleOpenQr,
        handleClose,
        isLoading
    } = useBags();

    if (isLoading) return <p>Loading...</p>;

    const columns = [
        { field: '_id', headerName: 'ID', width: 70, valueGetter: (params) => params.slice(-5) },
        {
            field: 'arrivalDate',
            headerName: 'Arrival Date',
            width: 130,
            valueFormatter: (value) => {
                if (isISO8601(value)) {
                    return formatISOToCustom(value);
                }
                return value;
            }
        },
        { field: 'lotNumber', headerName: 'Lot Number', width: 150 },
        { field: 'country', headerName: 'Country', width: 150, valueGetter: (params) => params?.name },
        { field: 'region', headerName: 'Region', width: 150 },
        { field: 'producerName', headerName: 'Producer Name', width: 150 },
        { field: 'harvest', headerName: 'Harvest', width: 150 },
        { field: 'type', headerName: 'Type', width: 100, valueGetter: (params) => params?.name },
        {
            field: 'variety',
            headerName: 'Variety',
            width: 220,
            valueGetter: (params) => {
                if (params.length === 0) {
                    return '';
                }
                return params.map(v => v?.name);
            }
        },
        { field: 'processing', headerName: 'Processing', width: 150, valueGetter: (params) => params?.name?.en },
        {
            field: 'qGrade',
            type: 'number',
            headerName: 'Q-Grade',
            width: 70,
            valueGetter: (params) => {
                if (params === 0) {
                    return '';
                }
                return params;
            }
        },
        {
            field: 'adjustedWeight',
            type: 'number',
            headerName: 'Weight',
            width: 80,
            valueGetter: (params) => {
                if (params === 0) {
                    return '';
                }
                return params;
            }
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 100,
            renderCell: (params) => (
                <TypographyBagStatus status={params.value} sx={{ height: '100%', alignContent: 'center' }}/>
            )
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: '',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ row }) => {
                return [
                    <GridActionsCellItem
                        icon={<LaunchIcon/>}
                        label="Open"
                        onClick={() => handleOpen(row._id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<QrCodeIcon/>}
                        label="QR Code"
                        onClick={() => handleOpenQr(row._id)}
                        color="inherit"
                    />
                ];
            }
        }
    ];

    return (
        <>
            <Toolbar/>
            <Container maxWidth="false" sx={{ mt: 4, mb: 4 }}>
                <Box
                    sx={{
                        height: '80vh',
                        width: '100%',
                        '& .actions': {
                            color: 'text.secondary',
                        },
                        '& .textPrimary': {
                            color: 'text.primary',
                        },
                    }}
                >
                    {( isLoading ) ?
                        ( <p>Loading...</p> ) :
                        (
                            <>
                                <DataGrid
                                    rows={rows}
                                    columns={columns}
                                    editMode="row"
                                    getRowId={(row) => row._id}
                                    // initialState={{
                                    //     sorting: {
                                    //         sortModel: [{ field: 'arrivalDate', sort: 'desc' }],
                                    //     },
                                    // }}
                                />
                                <Dialog
                                    open={open}
                                    onClose={handleClose}
                                    maxWidth="sm"
                                    fullWidth={true}
                                >
                                    <DialogTitle>QR Code</DialogTitle>
                                    <DialogContent style={{ textAlign: 'center' }}>
                                        <QRCodeSVG
                                            value={`${process.env.REACT_APP_URL}/bags/${qrCodeValue}`}
                                            size={256}
                                        />
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleClose}>Close</Button>
                                    </DialogActions>
                                </Dialog>
                            </>
                        )}
                </Box>
            </Container>
            <Alert ref={alertRef}/>
        </>
    );
};
