import React from 'react';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import {
    AddCircleOutline,
    Preview,
    Visibility,
    ShoppingBasket,
    Today
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { USER_ROLES } from '@shared/api/constants';


export const SecondaryListItems = ({ role }) => {
    if (role === USER_ROLES.ROASTER)
        return (
            <>
                <ListItemButton component={Link} to="../roasting-plan">
                    <ListItemIcon>
                        <Today color="primary"/>
                    </ListItemIcon>
                    <ListItemText primary="Roasting Plan"/>
                </ListItemButton>
                <ListItemButton component={Link} to="../batches">
                    <ListItemIcon>
                        <Visibility color="primary"/>
                    </ListItemIcon>
                    <ListItemText primary="Batches"/>
                </ListItemButton>
                <ListItemButton component={Link} to="../batches-completed">
                    <ListItemIcon>
                        <Visibility color="primary"/>
                    </ListItemIcon>
                    <ListItemText primary="Completed Batches"/>
                </ListItemButton>
            </>
        );

    if (role === USER_ROLES.INVENTOR)
        return (
            <>
                <ListItemButton component={Link} to="../add-bags">
                    <ListItemIcon>
                        <AddCircleOutline color="primary"/>
                    </ListItemIcon>
                    <ListItemText primary="Add Bags"/>
                </ListItemButton>
                <ListItemButton component={Link} to="../bags">
                    <ListItemIcon>
                        <Preview color="primary"/>
                    </ListItemIcon>
                    <ListItemText primary="Bags"/>
                </ListItemButton>
            </>
        );

    if (role === USER_ROLES.PICKER)
        return (
            <>
                <ListItemButton component={Link} to="../packs">
                    <ListItemIcon>
                        <ShoppingBasket color="primary"/>
                    </ListItemIcon>
                    <ListItemText primary="Packs"/>
                </ListItemButton>
            </>
        );

    if (role === USER_ROLES.ADMIN)
        return (
            <>
                <ListItemButton component={Link} to="../roasting-plan">
                    <ListItemIcon>
                        <Today color="primary"/>
                    </ListItemIcon>
                    <ListItemText primary="Roasting Plan"/>
                </ListItemButton>
                <ListItemButton component={Link} to="../add-bags">
                    <ListItemIcon>
                        <AddCircleOutline color="primary"/>
                    </ListItemIcon>
                    <ListItemText primary="Add Bags"/>
                </ListItemButton>
                <ListItemButton component={Link} to="../bags">
                    <ListItemIcon>
                        <Preview color="primary"/>
                    </ListItemIcon>
                    <ListItemText primary="Bags"/>
                </ListItemButton>
                <ListItemButton component={Link} to="../batches">
                    <ListItemIcon>
                        <Visibility color="primary"/>
                    </ListItemIcon>
                    <ListItemText primary="Batches"/>
                </ListItemButton>
                <ListItemButton component={Link} to="../batches-completed">
                    <ListItemIcon>
                        <Visibility color="primary"/>
                    </ListItemIcon>
                    <ListItemText primary="Completed Batches"/>
                </ListItemButton>
                <ListItemButton component={Link} to="../add-pack">
                    <ListItemIcon>
                        <ShoppingBasket color="primary"/>
                    </ListItemIcon>
                    <ListItemText primary="Add pack"/>
                </ListItemButton>
                <ListItemButton component={Link} to="../packs">
                    <ListItemIcon>
                        <ShoppingBasket color="primary"/>
                    </ListItemIcon>
                    <ListItemText primary="Packs"/>
                </ListItemButton>
            </>
        );

    return null;
};