import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
    Grid,
    Toolbar, Container, Box, Card, CardContent, Typography
} from '@mui/material';

import axios from '@shared/config/axios';
import { BATCH_STATUSES } from '@shared/api/constants';
import { useAuth } from '@providers/context/AuthContext';
import { Link } from 'react-router-dom';
import { TypographyBatchStatus } from '@features/typography-colored-status-batch';
import { formatISOToCustom, isISO8601 } from '@shared/api/utils';


export const BatchesCompletedPage = () => {
    const { userInfo } = useAuth();
    const [batchesData, setBatchesData] = useState([]);

    const { data } = useQuery({
        queryKey: ['batches-completed'],
        queryFn: async () => {
            return axios.get(`/users/${userInfo?._id}/batches`, {
                params: {
                    status: BATCH_STATUSES.COMPLETED,
                    weightAfter: false
                }
            }).then(({ data }) => data);
        }
    });
    useEffect(() => {
        if (data) {
            setBatchesData(data);
        }
    }, [data]);


    return (
        <>
            <Toolbar/>
            <Container
                maxWidth={false}
                sx={{
                    // height: '88vh',
                    display: 'flex',
                    justifyContent: 'center',
                    paddingTop: 2
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 3,
                        justifyContent: 'flex-start',
                        width: '100%',
                    }}
                >
                    {batchesData.map((batch) => (
                        <Box
                            key={batch?._id}
                            component={Link}
                            to={`../buckets/${batch?.bucketId}`}
                            sx={{
                                textDecoration: 'none',
                                borderRadius: '12px',
                                width: '200px', // Фиксированная ширина
                                alignSelf: 'flex-start', // Не позволяет растягивать элементы
                                transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Плавные переходы
                                '&:hover': {
                                    transform: 'scale(1.05)', // Эффект увеличения при наведении
                                    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)', // Увеличение тени при наведении
                                },
                            }}
                        >
                            <Card
                                sx={{
                                    width: '100%',
                                    overflow: 'hidden',
                                    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
                                    background: 'linear-gradient(135deg, #fafafa, #f0f0f0)',
                                    borderRadius: '12px',
                                    '&:hover': {
                                        background: 'linear-gradient(135deg, #ffffff, #f0f0f0)',
                                    },
                                }}
                            >
                                <CardContent>
                                    <Grid container direction="row" spacing={1} sx={{ marginBottom: 1 }}>
                                        <Grid item xs={6}>
                                            <Typography variant="subtitle2" color="textSecondary">Bucket ID</Typography>
                                            <Typography variant="body1">{batch?.bucketId}</Typography>
                                        </Grid>
                                        <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                            <Typography variant="subtitle2" color="textSecondary">Status</Typography>
                                            <TypographyBatchStatus status={batch?.status}/>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" spacing={1} sx={{ marginBottom: 1 }}>
                                        <Grid item xs={6}>
                                            <Typography variant="subtitle2" color="textSecondary">Weight</Typography>
                                            <Typography variant="body1">{batch?.weightBefore} kg</Typography>
                                        </Grid>
                                        <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                            <Typography variant="subtitle2" color="textSecondary">Roast
                                                Type</Typography>
                                            <Typography variant="body1">{batch?.roastType?.name?.en}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Typography variant="subtitle2" color="textSecondary" sx={{ marginBottom: 1 }}>
                                        Arrival Date
                                    </Typography>
                                    <Typography variant="body1">
                                        {batch?.arrivalDate
                                            ? isISO8601(batch.arrivalDate)
                                                ? formatISOToCustom(batch.arrivalDate)
                                                : batch.arrivalDate
                                            : ''
                                        }
                                    </Typography>
                                    <Typography variant="subtitle2" color="textSecondary" sx={{ marginTop: 1 }}>
                                        Country
                                    </Typography>
                                    <Grid container>
                                        <Grid item xs={10}>
                                            <Typography variant="body1">{batch?.bag?.country?.name}</Typography>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Box>
                    ))}
                </Box>
            </Container>
        </>
    );
};

