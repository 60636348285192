import { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import axios from '@shared/config/axios';
import { PACK_STATUSES } from '@shared/api/constants';
import { AxiosErrorAlertHandler } from '@shared/api/utils';


export const usePack = (packId) => {
    const queryClient = useQueryClient();
    const alertRef = useRef();

    const [pack, setPack] = useState('');

    const { data, refetch } = useQuery({
        queryKey: ['packs', packId],
        queryFn: async () => await axios.get(`/packs/${packId}`).then(({ data }) => data),
        refetchOnWindowFocus: true,
        retry: false,
    });

    useEffect(() => {
        if (data) {
            setPack(data);
        }
    }, [data]);

    useEffect(() => {
        if (data) {
            setPack(data);
        }

        if (packId) {
            localStorage.setItem('lastSelectedPackId', packId);
        }
    }, [data, packId]);

    const mutationStart = useMutation({
        mutationFn: async () => {
            return await axios.get(`/packs/${packId}/`, {
                params: {
                    getBucket: true
                }
            });
        },
        onSuccess: (pack) => {
            setPack(pack);
            queryClient.invalidateQueries(['packs', packId]); // Это обновит данные в кэше
            refetch();
        },
        onError: (error) => {
            AxiosErrorAlertHandler(error, alertRef);
        }
    });

    const mutationFinish = useMutation({
        mutationFn: async (newStatus) => {
            return await axios.patch(`/packs/${packId}/`, { status: newStatus });
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['packs', packId]); // Это обновит данные в кэше
            refetch();
        },
        onError: (error) => {
            AxiosErrorAlertHandler(error, alertRef);
        },
        retry: false
    });

    const handleStartPacking = () => {
        mutationStart.mutate();
    };

    const handleFinishPacking = async () => {
        try {
            const response = await axios.get(`/packs/images/${packId}`, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                responseType: 'blob'
            });
            mutationFinish.mutate(PACK_STATUSES.COMPLETED);

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');

            link.href = url;
            link.setAttribute('download', `${packId}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
            alertRef.current.handleOpen('success', 'Packaging successfully packed.');
        } catch (error) {
            AxiosErrorAlertHandler(error, alertRef);
        }
    };

    return {
        alertRef,
        pack, setPack,
        handleStartPacking, mutationStart,
        handleFinishPacking, mutationFinish
    };
};