import { Typography } from '@mui/material';
import { BATCH_STATUSES } from '@shared/api/constants';

export const TypographyBatchStatus = ({ status, variant = 'body2', sx = {} }) => {
    let color = 'textPrimary'; // Default color
    let text = '';

    if (!status) return <></>;

    switch (status) {
        case BATCH_STATUSES.INITIALIZED:
            color = 'warning.main'; // Yellow for initialization
            text = 'Initialization';
            break;
        case BATCH_STATUSES.IN_PROGRESS:
            color = 'info.main'; // Blue for in-progress
            text = 'In Progress';
            break;
        case BATCH_STATUSES.COMPLETED:
            color = 'success.main'; // Green for completed
            text = 'Completed';
            break;
        case BATCH_STATUSES.IN_STOCK:
            color = 'secondary.main'; // Default color
            text = 'In Stock';
            break;
        case BATCH_STATUSES.FINISHED:
            color = 'error.main'; // Red for finished
            text = 'Finished';
            break;
        default:
            text = '';
            break;
    }

    return (
        <Typography variant={variant} sx={{ color, ...sx }}>
            {text}
        </Typography>
    );
};

