import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import { useQuery } from '@tanstack/react-query';
import {
    Autocomplete,
    Box,
    FormControl,
    Grid,
    Toolbar,
    Button,
    Container,
    TextField,
    Paper,
    Chip,
    Select,
    OutlinedInput,
    InputLabel,
    MenuItem
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import axios from '@shared/config/axios';
import { Alert } from '@features/alert';
import { AxiosErrorAlertHandler } from '@shared/api/utils';


const minDate = dayjs('2022-01-01');
const maxDate = dayjs();
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const generateStackId = (lotNumber, countryId) => {
    const date = dayjs().format('YYYY-MM-DD');
    const uniqueIdentifier = uuidv4().split('-')[0];
    return `${date}_${lotNumber}_${countryId}_${uniqueIdentifier}`;
};

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export const AddingPage = () => {
    const theme = useTheme();
    const alertRef = useRef();

    const [isFormValid, setIsFormValid] = useState(false);

    // Stack Info
    const [stackId, setStackId] = useState('');
    const [weightValue, setWeightValue] = useState('');
    const [countBag, setCountBag] = useState('');

    // Bag info
    const [lotNumber, setLotNumber] = useState('');
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [region, setRegion] = useState('');
    const [producerName, setProducerName] = useState('');
    const [selectedHarvestFrom, setSelectedHarvestFrom] = useState(null);
    const [selectedHarvestTo, setSelectedHarvestTo] = useState(null);
    const [altitude, setAltitude] = useState('');
    const [selectedType, setSelectedType] = useState(null);
    const [selectedVariety, setSelectedVariety] = useState([]);
    const [descriptors, setDescriptors] = useState('');
    const [selectedProcessing, setSelectedProcessing] = useState(null);
    const [qGrade, setQGrade] = useState('');

    // Options for select
    const [optionCountries, setOptionCountries] = useState([]);
    const [optionVarieties, setOptionVarieties] = useState([]);
    const [optionTypes, setOptionTypes] = useState([]);
    const [optionProcesses, setOptionProcesses] = useState([]);

    const { data: varieties } = useQuery({
        queryKey: ['varieties-for-stack'],
        queryFn: () => {
            return axios.get(`/varieties`).then((data) => data);
        }
    });

    const { data: countries } = useQuery({
        queryKey: ['countries-for-stack'],
        queryFn: () => {
            return axios.get(`/countries`).then((data) => data);
        }
    });

    const { data: types } = useQuery({
        queryKey: ['types-for-stack'],
        queryFn: () => {
            return axios.get(`/types`).then((data) => data);
        }
    });

    const { data: processes } = useQuery({
        queryKey: ['processes-for-stack'],
        queryFn: () => {
            return axios.get(`/processes`).then((data) => data);
        }
    });

    useEffect(() => {
        if (varieties) {
            const newVarieties = varieties.data.map((item) => ( {
                label: item.name,
                value: item._id
            } ));
            setOptionVarieties(newVarieties);
        }
        if (countries) {
            const newCountries = countries.data.map((item) => ( {
                label: item.name,
                value: item._id
            } ));
            setOptionCountries(newCountries);
        }
        if (types) {
            const newTypes = types.data.map((item) => ( {
                label: item.name,
                value: item._id
            } ));
            setOptionTypes(newTypes);
        }
        if (processes) {
            const newProcesses = processes.data.map((item) => ( {
                label: item.name?.en,
                value: item._id
            } ));
            setOptionProcesses(newProcesses);
        }
    }, [varieties, countries, types, processes]);

    const clearStates = () => {
        setStackId('');
        setWeightValue(''); // Clear weight input after add-stack
        setCountBag(''); // Clear count input after add-stack
        setLotNumber(''); // Clear lot number input after add-stack
        setSelectedCountry(null); // Clear selected country after add-stack
        setRegion(''); // Clear region input after add-stack
        setProducerName(''); // Clear producer name input after add-stack
        setSelectedHarvestFrom(null); // Clear selected harvest date after add-stack
        setSelectedHarvestTo(null); // Clear selected harvest date after add-stack
        setAltitude(''); // Clear altitude input after add-stack
        setSelectedType(null); // Clear selected type after add-stack
        setSelectedVariety([]); // Clear selected variety after add-stack
        setDescriptors(''); // Clear descriptors textarea after add-stack
        setSelectedProcessing(null); // Clear selected processing after add-stack
        setQGrade(''); // Clear Q-grade input after add-stack
    };

    const handleWeightChange = (event) => {
        const value = event.target.value;
        if (/^\d*\.?\d*$/.test(value)) {
            setWeightValue(value);
        }
    };

    const handleCountBagChange = (event) => {
        const value = event.target.value;

        if (value === '' || /^\d+$/.test(value)) {
            const numericValue = value === '' ? '' : parseInt(value, 10);
            if (value === '' || ( numericValue > 0 && numericValue <= 50 )) {
                setCountBag(value);
            }
        }
    };

    const handleLotNumberChange = (event) => {
        const value = event.target.value;
        setLotNumber(value);
    };

    const handleCountryChange = (event, newValue) => {
        setSelectedCountry(newValue);
    };

    const handleRegionChange = (event) => {
        const value = event.target.value;
        setRegion(value);
    };

    const handleProducerNameChange = (event) => {
        const value = event.target.value;
        setProducerName(value);
    };

    const handleHarvestFromChange = (date) => {
        setSelectedHarvestFrom(date);
    };

    const handleHarvestToChange = (date) => {
        setSelectedHarvestTo(date);
    };

    const handleAltitudeChange = (event) => {
        const value = event.target.value;

        if (value === '' || /^\d+$/.test(value)) {
            const numericValue = value === '' ? '' : parseInt(value, 10);
            if (value === '' || ( numericValue > 0 && numericValue <= 8000 )) {
                setAltitude(value);
            }
        }
    };

    const handleTypeChange = (event) => {
        const selectedValue = event.target.value;
        const selectedOption = optionTypes.find(option => option.value === selectedValue);
        setSelectedType(selectedOption);
    };

    const handleVarietyChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedVariety(value);
    };

    const handleDescriptorsChange = (event) => {
        const value = event.target.value;
        setDescriptors(value);
    };

    const handleProcessingChange = (event) => {
        const selectedValue = event.target.value;
        const selectedOption = optionProcesses.find(option => option.value === selectedValue);
        setSelectedProcessing(selectedOption);
    };

    const handleQGradeChange = (event) => {
        const value = event.target.value;
        setQGrade(value);
    };

    useEffect(() => {
        if (isFormValid && lotNumber && selectedCountry) setStackId(generateStackId(lotNumber.replace(' ', '_'), selectedCountry ? selectedCountry?.label.replace(' ', '_') : ''));
    }, [isFormValid, selectedCountry, lotNumber]);

    useEffect(() => {
        const areDatesValid = selectedHarvestFrom && selectedHarvestTo &&
            !selectedHarvestFrom.isSame(selectedHarvestTo, 'month') &&
            selectedHarvestTo.isAfter(selectedHarvestFrom) &&
            selectedHarvestFrom.isAfter(minDate) && selectedHarvestFrom.isBefore(maxDate) &&
            selectedHarvestTo.isAfter(minDate) && selectedHarvestTo.isBefore(maxDate);

        setIsFormValid(
            weightValue !== '' &&
            countBag !== '' &&
            lotNumber !== '' &&
            selectedCountry !== null &&
            region !== '' &&
            producerName !== '' &&
            selectedHarvestFrom !== null &&
            selectedHarvestTo !== null &&
            areDatesValid &&
            altitude !== '' &&
            selectedType !== null &&
            selectedVariety.length > 0 &&
            descriptors !== '' &&
            selectedProcessing !== '' &&
            qGrade !== ''
        );
    }, [weightValue, countBag, lotNumber, selectedCountry, region, producerName, selectedHarvestFrom, selectedHarvestTo, altitude, selectedType, selectedVariety, descriptors, selectedProcessing, qGrade]);

    const handlePushStack = async () => {
        if (!isFormValid) {
            alertRef.current.handleOpen('warning', 'Please fill out all fields!');
            return;
        }

        let variety = selectedVariety.map(selectedItem => optionVarieties.find(option => option.value === selectedItem));

        const newItem = {
            stackId: stackId,
            weight: weightValue,
            count: countBag,
            arrivalDate: ( new Date() ).toISOString(),
            lotNumber: lotNumber,
            country: selectedCountry ? selectedCountry.value : '',
            region: region,
            producerName: producerName,
            harvest: ( selectedHarvestFrom || selectedHarvestTo ) ? `${selectedHarvestFrom.format('MM/YYYY')} - ${selectedHarvestTo.format('MM/YYYY')}` : null,
            altitude: altitude,
            type: selectedType ? selectedType.value : '',
            variety: variety ? variety.map(item => item.value) : [],
            descriptors: descriptors,
            processing: selectedProcessing ? selectedProcessing.value : '',
            qGrade: qGrade
        };

        try {
            const response = await axios.post('/stacks', newItem, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                responseType: 'blob'
            });

            alertRef.current.handleOpen('success', 'Bags loaded successfully.');

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');

            link.href = url;
            link.setAttribute('download', `${stackId}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
            clearStates();
        } catch (error) {
            AxiosErrorAlertHandler(error, alertRef);
        }
    };

    return (
        <>
            <Toolbar/>
            <Container
                maxWidth="sm"
                sx={{
                    marginTop: 4,
                    marginBottom: 4,
                }}
            >
                <Paper sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    // width: '35vw',
                    height: 'auto'
                }}>
                    <Grid container direction="row" spacing={2}>
                        {/* Stack ID */}
                        <Grid item xs={8}>
                            <FormControl fullWidth>
                                <TextField
                                    disabled
                                    label="Stack ID"
                                    variant="filled"
                                    value={stackId}
                                    fullWidth
                                />
                            </FormControl>
                        </Grid>
                        {/* Weight */}
                        <Grid item xs={4}>
                            <FormControl>
                                <TextField
                                    label="Weight, kg"
                                    variant="outlined"
                                    type="text"
                                    value={weightValue}
                                    onChange={handleWeightChange}
                                    fullWidth
                                />
                            </FormControl>
                        </Grid>
                        {/* Lot number */}
                        <Grid item xs={8}>
                            <FormControl fullWidth>
                                <TextField
                                    label="Lot Number"
                                    variant="outlined"
                                    value={lotNumber}
                                    onChange={handleLotNumberChange}
                                    fullWidth
                                />
                            </FormControl>
                        </Grid>
                        {/* Count bag */}
                        <Grid item xs={4}>
                            <FormControl>
                                <TextField
                                    label="Count"
                                    variant="outlined"
                                    value={countBag}
                                    onChange={handleCountBagChange}
                                    fullWidth
                                />
                            </FormControl>
                        </Grid>
                        {/* Country */}
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    options={optionCountries}
                                    autoHighlight
                                    getOptionLabel={(option) => option.label}
                                    value={selectedCountry}
                                    isOptionEqualToValue={(option, value) => option._id === value._id}
                                    onChange={handleCountryChange}
                                    renderInput={(params) => <TextField {...params} label="Country"/>}
                                    sx={{ width: '100%' }}
                                />
                            </FormControl>
                        </Grid>
                        {/* Region */}
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <TextField
                                    label="Region"
                                    variant="outlined"
                                    value={region}
                                    onChange={handleRegionChange}
                                    fullWidth
                                />
                            </FormControl>
                        </Grid>
                        {/* Producer Name */}
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <TextField
                                    label="Producer Name"
                                    variant="outlined"
                                    value={producerName}
                                    onChange={handleProducerNameChange}
                                    fullWidth
                                />
                            </FormControl>
                        </Grid>
                        {/* Altitude */}
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <TextField
                                    label="Altitude"
                                    variant="outlined"
                                    value={altitude}
                                    onChange={handleAltitudeChange}
                                    fullWidth
                                />
                            </FormControl>
                        </Grid>
                        {/* Q Grade*/}
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <TextField
                                    label="Q Grade"
                                    variant="outlined"
                                    value={qGrade}
                                    onChange={handleQGradeChange}
                                    fullWidth
                                />
                            </FormControl>
                        </Grid>
                        {/* Harvest date */}
                        <Grid item xs={12} container direction="row" spacing={2}>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            views={['year', 'month']}
                                            label="Harvest From"
                                            minDate={minDate}
                                            maxDate={maxDate}
                                            value={selectedHarvestFrom}
                                            onChange={handleHarvestFromChange}
                                            slotProps={{
                                                textField: {}
                                            }}
                                            format="MM/YYYY"
                                            sx={{ width: '100%' }}
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            views={['year', 'month']}
                                            label="Harvest To"
                                            minDate={minDate}
                                            maxDate={maxDate}
                                            value={selectedHarvestTo}
                                            onChange={handleHarvestToChange}
                                            slotProps={{
                                                textField: {}
                                            }}
                                            format="MM/YYYY"
                                            sx={{ width: '100%' }}
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            </Grid>
                        </Grid>
                        {/* Type */}
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id="select-type-label">Type</InputLabel>
                                <Select
                                    variant="outlined"
                                    labelId="select-type-label"
                                    label="Type"
                                    id="select-type"
                                    value={selectedType?.value || ''}
                                    onChange={handleTypeChange}
                                >
                                    {optionTypes.map((item) => (
                                        <MenuItem key={item.value} value={item.value}>
                                            {item.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {/* Variety */}
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-multiple-chip-label">Variety</InputLabel>
                                <Select
                                    variant="outlined"
                                    labelId="demo-multiple-chip-label"
                                    id="demo-multiple-chip"
                                    multiple
                                    value={selectedVariety}
                                    onChange={handleVarietyChange}
                                    input={<OutlinedInput id="select-multiple-chip" label="Variety"/>}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((selectedItem) => {
                                                const selectedOption = optionVarieties.find(option => option.value === selectedItem);
                                                return (
                                                    <Chip key={selectedItem} label={selectedOption?.label}/>
                                                );
                                            })}
                                        </Box>
                                    )}
                                    MenuProps={MenuProps}
                                >
                                    {
                                        optionVarieties.map((item) => (
                                            <MenuItem
                                                key={item.value}
                                                value={item.value}
                                                style={getStyles(item, selectedVariety, theme)}
                                            >
                                                {item.label}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        {/* Processing */}
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="select-processing-label">Processing</InputLabel>
                                <Select
                                    variant="outlined"
                                    labelId="select-processing-label"
                                    label="Processing"
                                    id="select-processing"
                                    value={selectedProcessing?.value || ''}
                                    onChange={handleProcessingChange}
                                >
                                    {optionProcesses.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {/* Descriptors */}
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    label="Descriptors"
                                    multiline
                                    value={descriptors}
                                    onChange={handleDescriptorsChange}
                                    minRows={1}
                                    maxRows={10}
                                />
                            </FormControl>
                        </Grid>
                        {/* Button`s */}
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                onClick={handlePushStack}
                                disabled={!isFormValid}
                                fullWidth
                            >
                                Push stack
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
            <Alert ref={alertRef}/>
        </>
    );
};
