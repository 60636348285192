import axios from '@shared/config/axios';

export const fetchAuth = async () => {
    const { data } = await axios.post('token/verification/auth');
    return data;
};

export const loginUser = async (values) => {
    const { data } = await axios.post('/users/log-in', values);
    return data;
};

export const logoutUser = async () => {
    const { data } = await axios.post('/users/log-out');
    return data;
};

export const fetchUser = async (values) => {
    const { data } = await axios.post('/users/account', values);
    return data;
};
