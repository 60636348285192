import { Routes, Route, Navigate } from 'react-router-dom';

import {
    AddingPage,
    BagViewPage,
    BagsViewPage,
    LoginPage,
    BatchesViewPage,
    BatchViewPage,
    BatchesCompletedPage,
    AddPackPage,
    PacksViewPage,
    PackPage,
    BucketPage, UnauthorizedPage, HomePage, RoastingPlanPage
} from '@pages/';
import { RoleBasedRoute, ProtectedRoute } from './providers';
import { USER_ROLES } from '@shared/api/constants';
import { AdminLayout, UserLayout } from '@app/layout/Layouts';
import {
    AdminDashboardPage,
    BagsPage,
    BatchesPage, CountriesPage,
    OrdersPage,
    PacksPage, ProcessesPage,
    RoastTypesPage, TypesPage,
    UsersPage, VarietiesPage
} from '@pages/admin';

export const App = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<UserLayout/>}>
                    <Route index path="/login" element={<LoginPage/>}/>
                    <Route element={<ProtectedRoute/>}>
                        <Route index element={<HomePage/>}/>
                        <Route element={<RoleBasedRoute requiredRoles={[USER_ROLES.ROASTER, USER_ROLES.ADMIN]}/>}>
                            <Route path="/batches" element={<BatchesViewPage/>}/>
                            <Route path="/batches/:id" element={<BatchViewPage/>}/>
                            <Route path="/batches-completed" element={<BatchesCompletedPage/>}/>
                            <Route path="/buckets/:id" element={<BucketPage/>}/>
                            <Route path="/roasting-plan" element={<RoastingPlanPage/>}/>
                        </Route>

                        <Route element={<RoleBasedRoute requiredRoles={[USER_ROLES.INVENTOR, USER_ROLES.ADMIN]}/>}>
                            <Route path="/add-bags" element={<AddingPage/>}/>
                            <Route path="/bags" element={<BagsViewPage/>}/>
                            <Route path="/bags/:id" element={<BagViewPage/>}/>
                        </Route>

                        <Route element={<RoleBasedRoute requiredRoles={[USER_ROLES.PICKER, USER_ROLES.ADMIN]}/>}>
                            <Route path="/packs" element={<PacksViewPage/>}/>
                            <Route path="/packs/:id" element={<PackPage/>}/>
                        </Route>

                        <Route element={<RoleBasedRoute requiredRoles={[USER_ROLES.ADMIN]}/>}>
                            <Route path="/add-pack" element={<AddPackPage/>}/>
                        </Route>
                    </Route>
                </Route>

                {/* Админка */}
                <Route path="/admin" element={<AdminLayout/>}>
                    <Route element={<ProtectedRoute/>}>
                        <Route element={<RoleBasedRoute requiredRoles={[USER_ROLES.ADMIN]}/>}>
                            <Route index element={<AdminDashboardPage/>}/>
                            <Route path="users" element={<UsersPage/>}/>
                            <Route path="bags" element={<BagsPage/>}/>
                            <Route path="batches" element={<BatchesPage/>}/>
                            <Route path="orders" element={<OrdersPage/>}/>
                            <Route path="packs" element={<PacksPage/>}/>
                            <Route path="countries" element={<CountriesPage/>}/>
                            <Route path="processes" element={<ProcessesPage/>}/>
                            <Route path="roast-types" element={<RoastTypesPage/>}/>
                            <Route path="types" element={<TypesPage/>}/>
                            <Route path="varieties" element={<VarietiesPage/>}/>
                            <Route path="*" element={<Navigate to="/admin" replace/>}/>
                        </Route>
                    </Route>
                </Route>

                {/* Страница "Нет доступа" */}
                <Route path="/unauthorized" element={<UnauthorizedPage/>}/>
            </Routes>
        </>
    );
};
