import { createContext, useContext } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { fetchAuth, loginUser, logoutUser, fetchUser } from '@shared/api/auth';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const queryClient = useQueryClient();

    const { data: authData, status, refetch } = useQuery({
        queryKey: ['auth'],
        queryFn: fetchAuth,
        refetchOnWindowFocus: false,
        retry: false
    });

    const getUser = useMutation({
        mutationFn: fetchUser,
        onSuccess: async (data) => {
            if (data) {
                window.localStorage.setItem('user-info', JSON.stringify({
                    _id: data?._id,
                    login: data?.login,
                    firstName: data?.firstName,
                    lastName: data?.lastName,
                    accountType: data?.accountType
                }));
                return data;
            } else {
                // alert('Failed to login!');
                return data;
            }
        }, onError: () => {
            // alert('Failed to login!');
        },
    });

    const login = useMutation({
        mutationFn: loginUser,
        onSuccess: async (data) => {
            if ('authToken' in data && 'authTokenExpire' in data) {
                window.localStorage.setItem('user-auth-token', JSON.stringify({
                    authToken: data.authToken, authTokenExpire: data.authTokenExpire,
                }));

                getUser.mutate();

                return data;
            } else {
                // alert('Failed to login!');
                return data;
            }
        }, onError: () => {
            // alert('Failed to login!');
        },
    });

    const logout = useMutation({
        mutationFn: logoutUser,
        onSuccess: async (data) => {
            if (data?.result) {
                window.localStorage.removeItem('user-auth-token');
                window.localStorage.removeItem('user-info');
                await queryClient.invalidateQueries({ queryKey: ['auth'] });
            }
        },
        onError: () => {
            // alert('Failed to login!');
        },
    });


    const isAuth = authData?.result || false;
    const userInfo = JSON.parse(window.localStorage.getItem('user-info'));

    return (
        <AuthContext.Provider value={{ isAuth, status, userInfo, login, logout, refetch }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};
