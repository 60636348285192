import React from 'react';
import {
    Box,
    Button,
    Chip,
    FormControl,
    Grid, InputLabel, MenuItem,
    Paper, Select,
    TextField,
    Typography
} from '@mui/material';

import { useBag } from '../api/useBag';
import { Alert } from '@features/alert';
import { TypographyBagStatus } from '@features/typography-colored-status-bag';
import { BAG_MINIMAL_WEIGHT, BAG_STATUSES } from '@shared/api/constants';
import { formatISOToCustom, isISO8601 } from '@shared/api/utils';

export const BagView = (params) => {
    const {
        bagData, loading,
        toggleFinished,
        bucket, handleBucketChange,
        weightValue, handleWeightChange, pushWeight,
        weightRoast, handleWeightRoastChange, toggleWeightRoast,
        roastType, optionRoastTypes, handleRoastTypeChange,
        alertRef
    } = useBag(params.bagId);


    return (
        <>
            <Paper
                sx={{
                    p: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 'auto',
                    backgroundColor: '#f9f9f9',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                    borderRadius: 2
                }}
            >
                <Typography variant="h5" gutterBottom sx={{ borderBottom: '2px solid #ccc', pb: 2 }}>
                    Bag Details
                </Typography>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                    {/* ID */}
                    <Grid item xs={12} sm={6}>
                        <Box display="flex" justifyContent="space-between"
                             sx={{ borderBottom: '1px solid #ccc', pb: 2 }}>
                            <Typography variant="body1"><strong>ID:</strong></Typography>
                            <Typography variant="body1">{bagData?._id}</Typography>
                        </Box>
                    </Grid>
                    {/* Date */}
                    <Grid item xs={12} sm={6}>
                        <Box display="flex" justifyContent="space-between"
                             sx={{ borderBottom: '1px solid #ccc', pb: 2 }}>
                            <Typography variant="body1"><strong>Arrival Date:</strong></Typography>
                            <Typography variant="body1">
                                {bagData?.arrivalDate
                                    ? isISO8601(bagData.arrivalDate)
                                        ? formatISOToCustom(bagData.arrivalDate)
                                        : bagData.arrivalDate
                                    : ''
                                }
                            </Typography>
                        </Box>
                    </Grid>
                    {/* Lot Number */}
                    <Grid item xs={12} sm={6}>
                        <Box display="flex" justifyContent="space-between"
                             sx={{ borderBottom: '1px solid #ccc', pb: 2 }}>
                            <Typography variant="body1"><strong>Lot Number:</strong></Typography>
                            <Typography variant="body1">{bagData?.lotNumber}</Typography>
                        </Box>
                    </Grid>
                    {/* Country */}
                    <Grid item xs={12} sm={6}>
                        <Box display="flex" justifyContent="space-between"
                             sx={{ borderBottom: '1px solid #ccc', pb: 2 }}>
                            <Typography variant="body1"><strong>Country:</strong></Typography>
                            <Typography variant="body1">{bagData?.country?.name}</Typography>
                        </Box>
                    </Grid>
                    {/* Region */}
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="space-between"
                             sx={{ borderBottom: '1px solid #ccc', pb: 2 }}>
                            <Typography variant="body1"><strong>Region:</strong></Typography>
                            <Typography variant="body1">{bagData?.region}</Typography>
                        </Box>
                    </Grid>
                    {/* Producer Name */}
                    <Grid item xs={12} sm={6}>
                        <Box display="flex" justifyContent="space-between"
                             sx={{ borderBottom: '1px solid #ccc', pb: 2 }}>
                            <Typography variant="body1"><strong>Producer Name:</strong></Typography>
                            <Typography variant="body1">{bagData?.producerName}</Typography>
                        </Box>
                    </Grid>
                    {/* Harvest */}
                    <Grid item xs={12} sm={6}>
                        <Box display="flex" justifyContent="space-between"
                             sx={{ borderBottom: '1px solid #ccc', pb: 2 }}>
                            <Typography variant="body1"><strong>Harvest:</strong></Typography>
                            <Typography variant="body1">{bagData?.harvest}</Typography>
                        </Box>
                    </Grid>
                    {/* Processing */}
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="space-between"
                             sx={{ borderBottom: '1px solid #ccc', pb: 2 }}>
                            <Typography variant="body1"><strong>Processing:</strong></Typography>
                            <Typography variant="body1">{bagData?.processing?.name?.en}</Typography>
                        </Box>
                    </Grid>
                    {/* Type */}
                    <Grid item xs={12} sm={4}>
                        <Box display="flex" justifyContent="space-between"
                             sx={{ borderBottom: '1px solid #ccc', pb: 2 }}>
                            <Typography variant="body1"><strong>Type:</strong></Typography>
                            <Typography variant="body1">{bagData?.type?.name}</Typography>
                        </Box>
                    </Grid>
                    {/* Q-Grade */}
                    <Grid item xs={12} sm={4}>
                        <Box display="flex" justifyContent="space-between"
                             sx={{ borderBottom: '1px solid #ccc', pb: 2 }}>
                            <Typography variant="body1"><strong>Q-Grade:</strong></Typography>
                            <Typography variant="body1">{bagData?.qGrade}</Typography>
                        </Box>
                    </Grid>
                    {/* Altitude */}
                    <Grid item xs={12} sm={4}>
                        <Box display="flex" justifyContent="space-between"
                             sx={{ borderBottom: '1px solid #ccc', pb: 2 }}>
                            <Typography variant="body1"><strong>Altitude:</strong></Typography>
                            <Typography variant="body1">{bagData?.altitude} m</Typography>
                        </Box>
                    </Grid>
                    {/* Variety */}
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="space-between"
                             sx={{ borderBottom: '1px solid #ccc', pb: 2 }}>
                            <Typography variant="body1" sx={{ mt: 0.5 }}><strong>Variety:</strong></Typography>
                            <Box display="flex" flexWrap="wrap" gap={1} maxWidth="70%">
                                {bagData?.variety ? (
                                    bagData.variety.map((v) => (
                                        <Chip key={v._id} label={v.name}/>
                                    ))
                                ) : (
                                    ''
                                )}
                            </Box>
                        </Box>
                    </Grid>
                    {/* Descriptors */}
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="space-between"
                             sx={{ borderBottom: '1px solid #ccc', pb: 2 }}>
                            <Typography variant="body1" sx={{ mr: 1 }}><strong>Descriptors:</strong></Typography>
                            <Typography variant="body1" sx={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                                {bagData?.descriptors}
                            </Typography>
                        </Box>
                    </Grid>
                    {/* Weight */}
                    <Grid item xs={12} sm={6}>
                        {( bagData?.weight || bagData?.weight === 0 )
                            ? (
                                <Box display="flex" justifyContent="space-between"
                                     sx={{ borderBottom: '1px solid #ccc', pb: 2 }}>
                                    <Typography variant="body1"><strong>Weight:</strong></Typography>
                                    <Typography variant="body1">{bagData?.adjustedWeight.toFixed(1)} kg</Typography>
                                </Box>
                            ) : (
                                <Box display="flex" justifyContent="space-between"
                                     sx={{
                                         borderRadius: '10px',
                                         pb: 2,
                                         px: 1,
                                         backgroundColor: '#ffcccc'
                                     }}>
                                    <Typography variant="body1"><strong>Weight:</strong></Typography>
                                    <Typography variant="body1">{bagData?.adjustedWeight} kg</Typography>
                                </Box>
                            )}
                    </Grid>
                    {/* Status */}
                    <Grid item xs={12} sm={6}>
                        <Box display="flex" justifyContent="space-between"
                             sx={{ borderBottom: '1px solid #ccc', pb: 2 }}>
                            <Typography variant="body1"><strong>Status:</strong></Typography>
                            <TypographyBagStatus status={bagData?.status} variant="body1"/>
                        </Box>
                    </Grid>
                    {/* Set/Roast Weight */}
                    <Grid item xs={12} sm={6} container direction="row" spacing={1}>
                        {!bagData?.weight && bagData?.status === BAG_STATUSES.INITIALIZED ? (
                            <>
                                <Grid item xs={12} sm={6}>
                                    <FormControl>
                                        <TextField
                                            label="Weight, kg"
                                            size="small"
                                            variant="outlined"
                                            type="text"
                                            value={weightValue}
                                            onChange={handleWeightChange}
                                            fullWidth
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="medium"
                                        onClick={pushWeight}
                                        disabled={loading || !weightValue}
                                    >
                                        Set Weight
                                    </Button>
                                </Grid>
                            </>
                        ) : bagData?.adjustedWeight > BAG_MINIMAL_WEIGHT && [BAG_STATUSES.IN_STOCK, BAG_STATUSES.ROASTING].includes(bagData?.status) ? (
                            <>
                                <Grid item xs={12} sm={6}>
                                    <FormControl>
                                        <TextField
                                            label="Weight, kg"
                                            size="small"
                                            variant="outlined"
                                            type="text"
                                            value={weightRoast}
                                            onChange={handleWeightRoastChange}
                                            fullWidth
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="medium"
                                        onClick={toggleWeightRoast}
                                        disabled={loading || !weightRoast || !bucket}
                                    >
                                        Get Roast
                                    </Button>
                                </Grid>
                            </>
                        ) : ( <></> )}
                    </Grid>
                    {/* Finished */}
                    <Grid item xs={12} sm={6} container direction="row-reverse" spacing={1}>
                        {bagData?.adjustedWeight > BAG_MINIMAL_WEIGHT && [BAG_STATUSES.IN_STOCK, BAG_STATUSES.ROASTING].includes(bagData?.status) ? (
                            <>
                                <Grid item xs={12} sm={6}>
                                    <FormControl>
                                        <TextField
                                            label="Bucket ID"
                                            size="small"
                                            variant="outlined"
                                            type="text"
                                            value={bucket}
                                            onChange={handleBucketChange}
                                            fullWidth
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth size="small">
                                        <InputLabel id="select-roast-type-label">Roast Type</InputLabel>
                                        <Select
                                            variant="outlined"
                                            id="select-roast-type"
                                            label="Roast Type"
                                            labelId="select-roast-type-label"
                                            value={roastType?.value || ''}
                                            onChange={handleRoastTypeChange}
                                        >
                                            {optionRoastTypes.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </>
                        ) : [BAG_STATUSES.IN_STOCK, BAG_STATUSES.ROASTING].includes(bagData?.status) && bagData?.adjustedWeight <= BAG_MINIMAL_WEIGHT ? (
                            <Grid item xs={12} sm={6}>
                                <Box display="flex" justifyContent="end">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        onClick={toggleFinished}
                                        disabled={loading || !bagData?.weight}
                                    >
                                        Finished
                                    </Button>
                                </Box>
                            </Grid>
                        ) : ( <></> )}
                    </Grid>
                </Grid>
            </Paper>
            <Alert ref={alertRef}/>
        </>
    );
};