import { useParams } from 'react-router-dom';
import { Container, Toolbar } from '@mui/material';

import { BagView } from '@widgets/view-bag/ui/BagView';


export const BagViewPage = () => {
    const { id } = useParams();

    return (
        <>
            <Toolbar/>
            <Container maxWidth="sm" sx={{ mt: 4, mb: 4 }}>
                <BagView bagId={id}/>
            </Container>
        </>
    );
};
