import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import {
    Button, Container, FormControl, Paper, TextField, Toolbar, Typography
} from '@mui/material';

import { useAuth } from '@providers/context/AuthContext';
import { USER_ROLES } from '@shared/api/constants';
import { Alert } from '@features/alert';
import { AxiosErrorAlertHandler } from '@shared/api/utils';

export const LoginPage = () => {
    const { isAuth, login, userInfo, refetch } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || '/';
    const alertRef = useRef();


    const roleRoutes = {
        [USER_ROLES.ADMIN]: '/admin',
        [USER_ROLES.ROASTER]: '/batches',
        [USER_ROLES.INVENTOR]: '/add-bags',
        [USER_ROLES.PICKER]: '/packs',
    };

    useEffect(() => {
        if (isAuth) {
            // Если пользователь уже авторизован, перенаправляем в зависимости от роли
            const defaultRoute = roleRoutes[userInfo?.accountType.systemName] || from;
            navigate(defaultRoute, { replace: true });
        }
        // eslint-disable-next-line
    }, [from, isAuth, navigate, userInfo]);

    const {
        register, handleSubmit, formState: { errors, isValid },
    } = useForm({
        mode: 'onChange',
    });

    const onSubmit = async (values) => {
        try {
            const data = await login.mutateAsync(values);
            if (data) {
                refetch();
                const role = data?.userInfo?.accountType?.systemName; // Получаем роль пользователя
                const targetRoute = roleRoutes[role] || '/';
                navigate(targetRoute, { replace: true });
            }
        } catch (err) {
            console.error('Authorization failed:', err);
            AxiosErrorAlertHandler(err, alertRef);
        }
    };

    return (
        <>
            <Toolbar/>
            <Container maxWidth="xs">
                <Paper
                    elevation={3}
                    sx={{
                        borderRadius: '10px',
                        display: 'flex',
                        flexDirection: 'column',
                        height: 'auto',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingBottom: 2,
                    }}
                >
                    <Typography sx={{ fontWeight: 'bold', marginTop: 2 }} variant="h5" align="center" gutterBottom>
                        Login
                    </Typography>
                    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }} noValidate autoComplete="off">
                        <FormControl
                            sx={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                height: 'auto',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <TextField
                                sx={{
                                    marginBottom: 1,
                                    width: '90%'
                                }}
                                label="Login"
                                error={Boolean(errors.login?.message)}
                                helperText={errors.login?.message}
                                {...register('login', { required: 'Enter your login' })}
                                margin="normal"
                            />
                            <TextField
                                sx={{
                                    width: '90%'
                                }}
                                label="Password"
                                type="password"
                                error={Boolean(errors.password?.message)}
                                helperText={errors.password?.message}
                                {...register('password', { required: 'Enter your password' })}
                                margin="normal"
                            />
                            <Button
                                disabled={!isValid}
                                type="submit"
                                size="large"
                                variant="contained"
                                sx={{
                                    marginTop: 1,
                                    width: '60%'
                                }}
                            >
                                Sign In
                            </Button>
                        </FormControl>
                    </form>
                </Paper>
            </Container>
            <Alert ref={alertRef}/>
        </>
    );
};